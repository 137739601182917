import cn from 'classnames';
import styles from './index.module.scss';
import { Arrow, ArrowPrev, MainButtonBatya, MainButtonCafe, Pause, Play } from 'assets/icons';
import { IDot } from './types';

const Dot = ({
    className,
    isDotVisible,
    isHoverSliderCafe,
    isHoverSliderBatya,
    isHoverCafe,
    isHoverBatya,
    isfullScreenBatya,
    isfullScreenCafe,
    position,
    video,
    isPlay
}: IDot) => (
    <div
        className={cn(className, styles.dot, {
            [styles.dot_hover]:
                isHoverSliderCafe ||
                isHoverSliderBatya ||
                isfullScreenCafe ||
                isfullScreenBatya ||
                video,
            [styles.dot_show]: isDotVisible,
            [styles.dot_cafe]: isHoverCafe,
            [styles.dot_batya]: isHoverBatya
        })}
        style={{
            top: position.top,
            left: position.left
        }}
    >
        {
            isHoverCafe &&
            !isfullScreenCafe &&
            <MainButtonCafe cnContent={cn(styles.svgContent, {
                [styles.svgContent_hover]: isHoverSliderCafe
            })} />
        }
        {
            (isHoverCafe || isHoverBatya) &&
            (isfullScreenCafe || isfullScreenBatya) &&
            position.left > (window.innerWidth / 2) &&
            <Arrow cnBg={isfullScreenBatya ? styles.arrowBatya : undefined} />
        }
        {
            (isHoverCafe || isHoverBatya) &&
            (isfullScreenCafe || isfullScreenBatya) &&
            position.left < (window.innerWidth / 2) &&
            <ArrowPrev cnBg={isfullScreenBatya ? styles.arrowBatya : undefined} />
        }
        {
            isHoverBatya &&
            !isfullScreenBatya &&
            <MainButtonBatya cnContent={cn(styles.svgContent, {
                [styles.svgContent_hover]: isHoverSliderBatya
            })} />
        }
        {
            video && !isPlay && <Play red />
        }
        {
            video && isPlay && <Pause red />
        }

    </div>
)

export default Dot;