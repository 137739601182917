import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BACKEND_URL } from 'constants/global';

const baseQuery = fetchBaseQuery({
  baseUrl: BACKEND_URL,
});

export const formAPISlice = createApi({
  reducerPath: 'formAPI',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    form: builder.mutation({
      query: (body) => ({
        url: 'notifications/',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useFormMutation } = formAPISlice;
