import { Layout } from "components/Layout";
import Routes from "constants/Routes";
import { storageInteraction } from "helpers/storageInteraction";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { setIsAdult } from "store/ageSlice";

import styles from './index.module.scss';

const CheckAge = () => {
    const dispatch = useDispatch();

    const confirmAge = () => {
      dispatch(setIsAdult());
      storageInteraction("isAdult", {isAdult: true});
    }
    
    return (
      <Layout className={styles.page}>
        <div className={styles.page__content}>
          <h3 className={styles.page__title}>вам 18+ ?</h3>

          <div className={styles.page__btnWrap}>
            <Link to={Routes.home} className={styles.page__btn}>нет</Link>
            <button onClick={confirmAge} className={styles.page__btn}>да</button>
          </div>
        </div>
      </Layout>
    )
}

export default CheckAge;