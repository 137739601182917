import { useEffect, useRef, useState } from 'react';
import cn from 'classnames';

import { ArrowSmall, CloseSliderIcon } from 'assets/icons';
import { ISlickSlide } from './types';

import styles from './index.module.scss';

const SlickSlide = ({
    className,
    data,
    sliderRef,
    isfullScreen,
    handleMouseLeave,
    handleMouseEnter,
    handleAreaClick,
    batya,
}: ISlickSlide) => {
    const [isVisibleInfo, setIsVisibleInfo] = useState<boolean>(false);
    const [height, setHeight] = useState<number>(1);
    const ref = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        !!ref?.current && setHeight(ref.current?.getBoundingClientRect().height);
    }, [ref.current])

    return (
        <div
            className={cn(className, styles.slide, {
                [styles.slide_padding]: isfullScreen
            })}
            key={data.id}
        >
            <div className={cn(styles.slide__img, {
                [styles.slide__img_batya]: batya,
                [styles.slide__img_full]: batya && isfullScreen
            })}>
                <img src={data.original} alt="" />
            </div>
            <button
                className={cn(styles.slide__btn, styles.slide__btn_prev, {
                    [styles.slide__btn_show]: isfullScreen
                })}
                onClick={() => !!sliderRef?.current && sliderRef?.current.slickPrev()}
            />
            <button
                className={cn(styles.slide__btn, styles.slide__btn_next, {
                    [styles.slide__btn_show]: isfullScreen
                })}
                onClick={() => !!sliderRef?.current && sliderRef?.current.slickNext()}
            />

            <button
                onMouseEnter={handleMouseLeave}
                onMouseLeave={handleMouseEnter}
                onClick={handleAreaClick}
                className={cn(styles.slide__close, {
                    [styles.slide__close_show]: isfullScreen
                })}
            >
                <CloseSliderIcon />
            </button>

            {!!data?.description && (
                <div
                    className={cn(styles.slide__slideInfo, styles.slideInfo, {
                        [styles.slide__slideInfo_batya]: batya,
                        [styles.slideInfo_show]: isfullScreen,
                        [styles.slideInfo_open]: isfullScreen && isVisibleInfo,
                        [styles.slideInfo_batya]: batya,
                    })}
                    onMouseEnter={handleMouseLeave}
                    onMouseLeave={handleMouseEnter}
                    style={
                        (window.innerWidth > 1279 && isfullScreen) ? {
                            transform: `translateY(calc(100% - ${height}px - 1.83vw))`
                        } : undefined
                    }
                >
                    <div
                        className={styles.slideInfo__wrap}
                        onClick={() => setIsVisibleInfo(!isVisibleInfo)}
                        ref={ref}
                    >

                        <h4 className={styles.slideInfo__title}>{data.title}</h4>
                        <div className={styles.slideInfo__arrow}>
                            <ArrowSmall />
                        </div>
                    </div>

                    <div className={styles.slideInfo__descr}>
                        <p>{data.description}</p>
                    </div>
                </div>
            )}
        </div>
    )
}

export default SlickSlide;