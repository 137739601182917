const PATTERN = /\D/g;

const getInputGuestsValue = (value: string) => {
  return value.replace(PATTERN, '');
};

export const handleGuestsInput = (evt: React.ChangeEvent<HTMLInputElement>) => {
  const input = evt.target;
  let inputNumbersValue = getInputGuestsValue(input.value);
  let formattedInputValue = '';
  if (!inputNumbersValue) {
    return (input.value = '');
  }
  if (inputNumbersValue[0] === '0') {
    formattedInputValue = '';
  } else {
    formattedInputValue = input.value;
  }
  if (Number(inputNumbersValue) > 50) {
    formattedInputValue = '50';
  }
  return (input.value = formattedInputValue);
};
