import styles from './index.module.scss';

import cn from 'classnames';

import { Image } from '../../../../components/Image';
import { TitleBlock } from '../../../../components/PageBlocks/TitleBlock';

import { CSSTransition } from 'react-transition-group';
import { useModal } from 'hooks/useModal';
import { FormModal } from 'layouts/FormModal';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { useRef } from 'react';
import useIntersectionObserver from 'hooks/useIntersectionObserver';

interface AboutBlockProps {
  data: {
    title: string;
    main_info: string;
    img: {
      href: string;
      alt: string;
    }[];
    info: string;
  };
}

const AboutBlock = ({ data }: AboutBlockProps) => {
  const windowWidth = useWindowWidth();
  const { isShowing, toggle } = useModal();

  //Title
  const refTitle = useRef<HTMLDivElement | null>(null);
  const entryTitle = useIntersectionObserver(refTitle, {});
  const isVisibleTitle = !!entryTitle?.isIntersecting;

  //Section
  const refInfo = useRef<HTMLDivElement | null>(null);
  const entryInfo = useIntersectionObserver(refInfo, {});
  const isVisibleInfo = !!entryInfo?.isIntersecting;

  //Img1
  const refImg1 = useRef<HTMLDivElement | null>(null);
  const entryImg1 = useIntersectionObserver(refImg1, {});
  const isVisibleImg1 = !!entryImg1?.isIntersecting;

  //Img2
  const refImg2 = useRef<HTMLDivElement | null>(null);
  const isVisibleImg2 = !!entryImg1?.isIntersecting;

  return (
    <>
      <div className={styles.about}>
        <TitleBlock
          className={cn(styles.about__title, {
            [styles.about__title_active]: isVisibleTitle,
          })}
          ref={refTitle}
        >
          {data.title}
        </TitleBlock>
        <div className={cn(styles.about__section, styles.section)}>
          <div
            className={cn(styles.section__info, {
              [styles.section__info_active]: isVisibleInfo,
            })}
            ref={refInfo}
          >
            <p className={styles.section__info_main}>{data.main_info}</p>
            <p className={styles.section__info_extra}>{data.info}</p>
          </div>
          <div className={cn(styles.about__content, styles.content)}>
            <Image
              src={data.img[0].href}
              alt={data.img[0].alt}
              imageClass={styles.content__container_image}
              containerClass={cn(styles.content__container, {
                [styles.content__container_active]: isVisibleImg1,
              })}
              ref={refImg1}
            />
            <Image
              src={data.img[1].href}
              alt={data.img[1].alt}
              imageClass={styles.content__container_image}
              containerClass={cn(styles.content__container, {
                [styles.content__container_active]: isVisibleImg2,
              })}
              ref={refImg2}
            />
          </div>
        </div>
        {windowWidth && windowWidth <= 1279 && (
          <h5 className={styles.about__link} onClick={toggle}>
            Забронировать столик
          </h5>
        )}
      </div>
      <CSSTransition
        in={isShowing}
        timeout={1000}
        classNames={{
          enterActive: styles.modal_enter_active,
          enterDone: styles.modal_enter_done,
          exitActive: styles.modal_exit_active,
          exit: styles.modal_exit,
        }}
        mountOnEnter
        unmountOnExit
      >
        <FormModal hide={toggle} formType={'black'} formName={'booking'} />
      </CSSTransition>
    </>
  );
};

export { AboutBlock };
