import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';

import './assets/scss/index.scss';
import { LoadingPage } from './pages/LoadingPage';

import { AppDispatch } from 'store/store';
import { dataSelector, getData } from 'store/allDataSlice';

import Routing from './pages/Routes';
import { Header } from 'layouts/Header';
import { useLocation } from 'react-router-dom';
import { useWindowSize } from 'hooks/useWindowSize';
import { Helmet } from 'react-helmet'
import { useMetaTags } from 'hooks/useMetaTags';

const App = () => {
	const windowSize = useWindowSize();
	const dispatch = useDispatch<AppDispatch>();
	const location = useLocation();
	const metaTags = useMetaTags();

	const { isLoading } = useSelector(dataSelector);

	useEffect(() => {
		dispatch(getData());
	}, []);

	useEffect(() => {
		document.body.style.setProperty("--app-height", `${windowSize.height}px`)
	}, [windowSize]);

	useEffect(() => {
		const element = location.hash && document.querySelector(location.hash);
		if (!element) return;

		element.scrollIntoView({
			behavior: 'smooth',
			block: 'start',
			inline: "start"
		});
	}, [location]);

	if (isLoading) {
		return <LoadingPage />;
	}

	return (
		<>
			<Helmet>
				<title>{metaTags.title}</title>
				<meta name="title" content={metaTags.metaTitle} />
				<meta name="description" content={metaTags.metaDescription} />
			</Helmet>
			<Header />
			<Routing />
		</>
	);
};

export { App };
