import styles from './index.module.scss';

import cn from 'classnames';
import { Link } from 'react-router-dom';

interface FooterProps {
  data: {
    name: string;
    href: string;
  }[];
}

const Footer = ({ data }: FooterProps) => {
  return (
    <footer className={cn(styles.menu__footer, styles.footer)}>
      <div className={styles.footer__wrapper}>
        <ul className={styles.footer__list}>
          {!!data?.length && data.map((item: any, index: number) => (
            <li className={styles.footer__list_item} key={index}>
              <Link to={item.href} className={styles.footer__list_item_link}>
                {item.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </footer>
  );
};

export { Footer };
