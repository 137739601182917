const PATTERN = /\D/g;

const getInputNumbersValue = (value: string) => {
  return value.replace(PATTERN, '');
};

export const handleDateInput = (evt: React.ChangeEvent<HTMLInputElement>) => {
  const input = evt.target;
  let inputNumbersValue = getInputNumbersValue(input.value);
  let formattedInputValue = '';
  const selectionStart = input.selectionStart;
  if (!inputNumbersValue) {
    return (input.value = '');
  }
  if (input.value.length !== selectionStart) {
    return;
  }
  if (['0', '1', '2', '3'].indexOf(inputNumbersValue[0]) > -1) {
    formattedInputValue += inputNumbersValue[0];
    if (inputNumbersValue[0] === '0') {
      if (
        ['1', '2', '3', '4', '5', '6', '7', '8', '9'].indexOf(
          inputNumbersValue[1]
        ) > -1
      ) {
        formattedInputValue += inputNumbersValue[1] + '.';
      }
    }
    if (inputNumbersValue[0] === '1' || inputNumbersValue[0] === '2') {
      if (
        ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].indexOf(
          inputNumbersValue[1]
        ) > -1
      ) {
        formattedInputValue += inputNumbersValue[1] + '.';
      }
    }
    if (inputNumbersValue[0] === '3') {
      if (['0', '1'].indexOf(inputNumbersValue[1]) > -1) {
        formattedInputValue += inputNumbersValue[1] + '.';
      }
    }
    if (['0'].indexOf(inputNumbersValue[2]) > -1) {
      formattedInputValue += inputNumbersValue[2];
      if (
        ['1', '2', '3', '4', '5', '6', '7', '8', '9'].indexOf(
          inputNumbersValue[3]
        ) > -1
      ) {
        formattedInputValue += inputNumbersValue[3];
      }
    }
    if (['1'].indexOf(inputNumbersValue[2]) > -1) {
      formattedInputValue += inputNumbersValue[2];
      if (['0', '1', '2'].indexOf(inputNumbersValue[3]) > -1) {
        formattedInputValue += inputNumbersValue[3];
      }
    }
  }
  input.value = formattedInputValue;
};

export const formattedDateInputValue = (
  evt: React.KeyboardEvent<HTMLInputElement>
) => {
  const input = evt.target as HTMLInputElement;

  if (
    evt.key === 'Backspace' &&
    getInputNumbersValue(input.value).length === 2
  ) {
    input.value = input.value.slice(0, 2);
  }

  return input;
};

export const handleDatePaste = (
  event: React.ClipboardEvent<HTMLInputElement>
) => {
  const pasted = event.clipboardData ?? window['clipboardData' as any];
  const input = event.target as HTMLInputElement;
  const inputNumbersValue = getInputNumbersValue(input.value);
  if (pasted) {
    const pastedText = pasted.getData('Text');
    if (PATTERN.test(pastedText)) {
      input.value = inputNumbersValue;
    }
  }
};
