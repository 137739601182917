import { useRef, useEffect } from 'react';

export const useHorizontalScroll = () => {
  const elRef = useRef() as React.RefObject<HTMLDivElement>;
  useEffect(() => {
    const el = elRef.current;
    if (el) {
      const onWheel = (e: any) => {
        if (e.deltaY === 0) return;
        e.preventDefault();
        el.scrollBy(e.deltaY, 0);
      };
      if (window.innerWidth > 1279) {
        el.addEventListener('wheel', onWheel);
      }
      return () => el.removeEventListener('wheel', onWheel);
    }
  }, []);
  return elRef;
};

