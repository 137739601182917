import { Layout } from '../../components/Layout';
import { PageFooter } from '../../components/PageFooter';

import { PageContent } from '../../components/PageContent';
import { DescriptionBlock } from './sections/DescriptionBlock';
import { TitleBlock } from '../../components/PageBlocks/TitleBlock';
import { SliderBlock } from '../../components/PageBlocks/SliderBlock';
import { ProductsListBlock } from '../../components/PageBlocks/ProductsListBlock';
import { AdvertisingBlock } from '../../components/PageBlocks/AdvertisingBlock';
import { NextPageBlock } from '../../components/PageBlocks/NextPageBlock';

import styles from './index.module.scss';

import Routes from '../../constants/Routes';

import { useWindowWidth } from 'hooks/useWindowWidth';
import { RootState } from 'store/store';
import { useSelector } from 'react-redux';
import { ageSelector } from 'store/ageSlice';

import { filterProductsData } from 'utils/filterProducts';
import CheckAge from './sections/CheckAge';

const pageData = {
	title: 'Винный магазин',
	footer: {
		info: 'Винный бутик основан в октябре 2009 года',
		link: ['Задать вопрос по магазину'],
		formTitle: 'Задать вопрос'
	},
	next: {
		name: 'Гастрономическая лавка',
		link: `${Routes.gastronomic_shop}`
	}
};

const VineShopPage = () => {
	const windowWidth = useWindowWidth();

	const { isAdult } = useSelector(ageSelector);
	const magaz = useSelector((state: RootState) => state.data.data.magaz);

	const productsData = {
		block: filterProductsData(magaz.prodcat, 'all'),
		extra_block: filterProductsData(magaz.prodcat, 'static')
	};

	return (
		<>
			{isAdult ? (
				<Layout className={styles.page}>
					<PageContent>
						<TitleBlock className={styles.page__title} type='h1'>
							{magaz.name}
						</TitleBlock>
						<SliderBlock
							data={magaz.images['group-slide']}
							className={styles.page__slider}
							convertColors
						/>
						<div className={styles.page__info}>{magaz.description}</div>
						<DescriptionBlock data={magaz.images['group-image'][0]} />
						<ProductsListBlock
							data={productsData}
							className={styles.page__cards}
							type='small'
							vine
							modalType='magaz'
						/>
						<AdvertisingBlock data={magaz.images['group-image'].slice(1)} />
						<NextPageBlock data={pageData.next} />
					</PageContent>
					{windowWidth && windowWidth > 1279 && (
						<PageFooter
							data={pageData.footer}
							className={styles.page__footer}
							formType={'red'}
						/>
					)}
				</Layout>
			) : (
				<CheckAge />
			)}
		</>
	);
};

export { VineShopPage };
