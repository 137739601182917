import { ArrowSmall, BatyaSmall, CloseSliderIcon, MainButtonBatya, MainPageLogoBatya } from "assets/icons";
import cn from "classnames";
import CustomLink from "components/CustomLink";
import Routes from "constants/Routes";
import { useState } from "react";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import { RootState } from "store/store";
import SlickSlide from "../SlickSlide";

import batyaPlug from '../../../../assets/images/batya_static.jpg';

import styles from './index.module.scss';
import { IBatyaBlock } from "./types";

const BatyaBlock = ({
    isHoverBatya,
    isfullScreenCafe,
    isfullScreenBatya,
    handleHoverBatya,
    innerRef,
    data,
    cnBlock,
    cnArea,
    cnFooter,
    cnStatic,
    handleAreaEnter,
    handleAreaLeave,
    handleAreaClick,
    handleMouseLeave,
    handleMouseEnter,
}: IBatyaBlock) => {
    const mainData = useSelector((state: RootState) => state.data.data);

    const [staticName, setStaticName] = useState<string | null>(null);
    const [slideIndex, setSlideIndex] = useState<number>(1);
    const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0);

    return (
        <div
            className={cnBlock}
            onMouseEnter={handleHoverBatya}
        >
            {!!data?.batyaSlider
                ? (
                    <Slider
                        infinite={true}
                        autoplaySpeed={2000}
                        arrows={false}
                        className={styles.slider}
                        ref={innerRef}
                        afterChange={(currentSlide: number) => {
                            setSlideIndex(currentSlide + 1)
                            setCurrentSlideIndex(currentSlide)
                        }}
                        responsive={[
                            {
                                breakpoint: 1279,
                                settings: {
                                    fade: !isfullScreenBatya,
                                    vertical: isfullScreenBatya,
                                    verticalSwiping: isfullScreenBatya,
                                }
                            },
                        ]}
                    >
                        {data.batyaSlider.map((n: any, i: number) =>
                            <SlickSlide
                                key={i}
                                className={styles.slider__slide}
                                data={n}
                                sliderRef={innerRef}
                                isfullScreen={isfullScreenBatya}
                                handleMouseLeave={handleMouseLeave}
                                handleMouseEnter={handleMouseEnter}
                                handleAreaClick={handleAreaClick}
                                batya
                            />
                        )}
                    </Slider>
                )
                : (
                    <div className={styles.slider__plug}>
                        <img src={batyaPlug} alt="" />
                    </div>
                )}

            {!!data?.batyaSlider && (
                <div className={cn(styles.slideDescr, {
                    [styles.slideDescr_show]: isfullScreenBatya
                })}>
                    {!!data?.batyaSlider.length &&
                        data.batyaSlider.map((n: any, i: number) => (
                            <div
                                key={i}
                                className={cn(styles.slideDescr__wrap, {
                                    [styles.slideDescr__wrap_show]: currentSlideIndex === i
                                })}
                            >
                                <h4 className={styles.slideDescr__title}>{n.title}</h4>
                                <div className={styles.slideDescr__text}>
                                    <p>{n.description}</p>
                                </div>
                            </div>
                        ))}
                </div>
            )}

            {!!data?.batyaSlider && data?.batyaSlider.length > 1 && (
                <div className={cn(styles.pagination, {
                    [styles.pagination_show]: isfullScreenBatya
                })}>
                    <span>{slideIndex}/{data.batyaSlider.length}</span>
                </div>
            )}

            <div className={cn(cnStatic, styles.static)}>
                {!!mainData?.merch?.images?.navigationStatic?.length && (
                    <img
                        src={mainData.merch.images.navigationStatic[0].original}
                        alt=""
                        className={cn(styles.static__img, {
                            [styles.static__img_show]: staticName === "merch"
                        })} />
                )}
                {!!mainData?.cafe?.images?.navigationStatic?.length && (
                    <img
                        src={mainData.cafe.images.navigationStatic[0].original}
                        alt=""
                        className={cn(styles.static__img, {
                            [styles.static__img_show]: staticName === "cafe"
                        })} />
                )}
            </div>

            <button
                onClick={handleAreaClick}
                className={cn(styles.sliderClose, {
                    [styles.sliderClose_show]: isfullScreenBatya
                })}
            >
                <CloseSliderIcon />
            </button>

            {!!data?.batyaSlider && data?.batyaSlider.length > 1 && (
                <span
                    className={cnArea}
                    onMouseEnter={handleAreaEnter}
                    onMouseLeave={handleAreaLeave}
                    onClick={handleAreaClick}
                ></span>
            )}

            <div className={cn(styles.logoBatya, {
                [styles.logoBatya_show]: isHoverBatya && !isfullScreenBatya
            })}>
                <BatyaSmall />
            </div>

            <div
                className={cn(styles.footerXs, {
                    [styles.footerXs_show]: !isHoverBatya,
                    [styles.footerXs_hide]: isHoverBatya
                })}
            >
                <div className={cn(styles.footerXs__logo)}>
                    <MainPageLogoBatya />
                </div>

                <div className={styles.footerXs__arrow}>
                    <ArrowSmall />
                </div>
            </div>

            <div className={cn(cnFooter, styles.footer, {
                [styles.footer_hover]: isHoverBatya,
                [styles.footer_hide]: isfullScreenBatya || isfullScreenCafe
            })}>
                {!!data?.batyaSlider && data?.batyaSlider.length > 1 && (
                    <div className={styles.footer__icon} onClick={handleAreaClick}>
                        <MainButtonBatya />
                    </div>
                )}

                <div className={cn(styles.footer__logo, styles.footer__logo_batya)}>
                    <MainPageLogoBatya />
                </div>
                <nav className={cn(styles.footer__nav, styles.nav, styles.nav_batya)}>
                    <ul className={styles.nav__list}>
                        <li
                            className={styles.nav__item}
                            onMouseEnter={() => setStaticName("merch")}
                            onMouseLeave={() => setStaticName(null)}
                        >
                            <CustomLink
                                href={Routes.merch}
                                name={"мерч"}
                                className={styles.nav__link}
                                black
                            />
                        </li>
                        <li
                            className={styles.nav__item}
                            onMouseEnter={() => setStaticName("cafe")}
                            onMouseLeave={() => setStaticName(null)}
                        >
                            <CustomLink
                                href={Routes.cafe}
                                name={"кафе"}
                                className={styles.nav__link}
                                black
                            />
                        </li>
                    </ul>
                </nav>

                <span className={cn(styles.footer__dividerBatya, {
                    [styles.footer__dividerBatya_hover]: isHoverBatya
                })}></span>

                <div className={cn(styles.footer__socials, styles.socials, styles.socials_batya)}>
                    <ul className={styles.socials__list}>
                        {!!mainData?.social.b29.vkontakte && (
                            <li className={styles.socials__item}>
                                <a href={mainData?.social.b29.vkontakte} target="_blank" className={styles.socials__link}>vk</a>
                            </li>
                        )}
                        {!!mainData?.social.b29.telegram && (
                            <li className={styles.socials__item}>
                                <a href={mainData?.social.b29.telegram} target="_blank" className={styles.socials__link}>telegram</a>
                            </li>
                        )}
                        {!!mainData?.social.b29.instagram && (
                            <li className={styles.socials__item}>
                                <a href={mainData?.social.b29.instagram} target="_blank" className={styles.socials__link}>instagram</a>
                            </li>
                        )}
                    </ul>
                    {!!mainData?.config.poster_file_batya && (
                        <CustomLink
                            href={mainData.config.poster_file_batya}
                            name={'афиша'}
                            className={styles.socials__poster}
                            black
                            hoverIsVisibleBatya
                            download
                        />
                    )}
                </div>

            </div>

        </div>
    )
}

export default BatyaBlock;