import styles from "./index.module.scss";

import { TitleBlock } from "../TitleBlock";
import { ProductCard } from "../../ProductCard";

import cn from "classnames";
import { useRef } from "react";
import useIntersectionObserver from "hooks/useIntersectionObserver";

const ProductsBlock = ({ el, tovar_main, className, vine, modalType, type }: any) => {
  //Title
  const refTitle = useRef<HTMLDivElement | null>(null);
  const entryTitle = useIntersectionObserver(refTitle, {});
  const isVisibleTitle = !!entryTitle?.isIntersecting;

  //Descr
  const refDescr = useRef<HTMLDivElement | null>(null);
  const entryDescr = useIntersectionObserver(refDescr, {});
  const isVisibleDescr = !!entryDescr?.isIntersecting;

  return (
    <div key={el.id} className={styles.products__block}>
      {!!el?.title && (
        <TitleBlock
          ref={refTitle}
          className={cn(styles.products__title, {
            [styles.products__title_active]: isVisibleTitle,
          })}
        >
          {el?.title}
        </TitleBlock>
      )}

      {!!el.preview && (
        <div className={cn(styles.products__decsription, styles.description)} ref={refDescr}>
          {!!el?.description && (
            <p
              ref={refDescr}
              className={cn(styles.description__info, {
                [styles.description__info_active]: isVisibleDescr,
              })}
            >
              {el?.description}
            </p>
          )}

          <div
            className={cn(styles.description__container, {
              [styles.description__container_active]: isVisibleDescr,
            })}
          >
            <img src={el?.preview?.original} alt="Карта" />
          </div>
        </div>
      )}
      {!!tovar_main[0]?.length && (
        <div className={cn(styles.products__cards, className, {
          [styles.products__cards_mod]: vine && tovar_main[0].length > 3
        })}>
          {tovar_main[0].map((product: any, index: number) => (
            <ProductCard
              product={product}
              key={index}
              type={type}
              modalType={modalType}
            />
          ))}
        </div>
      )}
    </div>
  );
};

interface ProductsListBlockProps {
  data: {
    block: any[];
    extra_block?: any;
  };
  className?: string;
  vine?: boolean;
  type?: "big" | "small";
  modalType: "lavka" | "magaz";
}

const ProductsListBlock = ({
  data,
  className,
  type,
  vine,
  modalType,
}: ProductsListBlockProps) => {
  const tovar_extra =
    !!data?.extra_block?.length && (Object.values(data.extra_block[0].tovar) as any);

  return (
    <div className={styles.products}>
      {!!data?.block.length && data.block.map((el: any, index: number) => {
        const tovar_main = !!el?.tovar && Object.values(el.tovar) as any;

        return (
          <ProductsBlock
            el={el}
            tovar_main={tovar_main}
            type={type}
            vine={vine}
            modalType={modalType}
            className={className}
            key={el.id}
          />
        );
      })}
      {!!tovar_extra.length && (
        <div className={cn(styles.products__extra)}>
          {tovar_extra[0].map((product: any, index: number) => {
            return (
              <ProductCard
                className={styles.products__extraCard}
                product={product}
                key={index}
                type={type}
                modalType={modalType}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export { ProductsListBlock };
