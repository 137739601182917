import { Layout } from '../../components/Layout';
import { PageFooter } from '../../components/PageFooter';
import { PageContent } from '../../components/PageContent';

import { TitleBlock } from '../../components/PageBlocks/TitleBlock';
import { SliderBlock } from '../../components/PageBlocks/SliderBlock';
import { NextPageBlock } from '../../components/PageBlocks/NextPageBlock';
import { MenuBlock } from '../../components/PageBlocks/MenuBlock';
import { AboutBlock } from './sections/AboutBlock';

import styles from './index.module.scss';

import Routes from '../../constants/Routes';

import { useWindowWidth } from 'hooks/useWindowWidth';

import { RootState } from 'store/store';
import { useSelector } from 'react-redux';

const pageData = {
  title: 'Кафе',
  footer: {
    link: ['Забронировать столик', 'Задать вопрос по кафе'],
    formTitle: 'Задать вопрос',
  },
  next: {
    name: 'Мерч',
    link: `${Routes.merch}`,
  },
};

const CafePage = () => {
  const cafe = useSelector((state: RootState) => state.data.data.cafe);
  const menu = useSelector((state: RootState) => state.data.data);

  const menuData = {
    title: 'Меню',
    list: menu.config.cafe,
  };

  const aboutData = {
    title: 'О кафе',
    main_info: cafe.description,
    img: [
      {
        href: cafe.images['group-image'][1].original,
        alt: cafe.images['group-image'][1].title,
      },
      {
        href: cafe.images['group-image'][0].original,
        alt: cafe.images['group-image'][0].title,
      },
    ],
    info: cafe.text_block4,
  };

  const windowWidth = useWindowWidth();

  return (
    <Layout className={styles.page}>
      {/* <Header type='dark' className={styles.page__header} /> */}
      <PageContent>
        <TitleBlock className={styles.page__title} type='h1'>
          {cafe.name}
        </TitleBlock>
        <SliderBlock
          data={cafe.images['group-slide']}
          className={styles.page__slider}
        />
        <MenuBlock data={menuData} className={styles.page__menu} />
        <AboutBlock data={aboutData} />
        <NextPageBlock className={styles.page__next} data={pageData.next} />
      </PageContent>
      {windowWidth && windowWidth > 1279 && (
        <PageFooter
          data={pageData.footer}
          className={styles.page__footer}
          formType={'black'}
        />
      )}
    </Layout>
  );
};

export { CafePage };
