import { ForwardedRef } from 'react';

import styles from './index.module.scss';

import cn from 'classnames';

import shopCursor from '../../assets/images/cursor_shop.svg';
import React from 'react';

interface ImageProps {
  src: string;
  alt: string;
  containerClass?: string;
  imageClass?: string;
  cursor?: string;
  onClick?: any;
}

const Image = React.forwardRef(
  (
    { src, alt, containerClass, imageClass, cursor, onClick }: ImageProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => (
    <div
      className={cn(styles.container, containerClass, {
        [styles.container_custom]: cursor === 'customMob',
      })}
      style={{
        cursor: cursor === 'custom' ? `url(${shopCursor}), auto` : `auto`,
      }}
      onClick={() => {
        cursor && cursor !== 'customMob' && onClick();
      }}
      ref={ref ? ref : null}
    >
      <img
        src={src}
        alt={alt}
        className={cn(styles.container__image, imageClass)}
      />
      {cursor === 'customMob' && (
        <img
          src={shopCursor}
          alt={'Заказать'}
          className={cn(styles.container__image_order)}
          onClick={onClick}
        />
      )}
    </div>
  )
);

export { Image };
