import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import { CafePage } from "pages/CafePage";
import { CareerPage } from "pages/CareerPage";
import { ContactsPage } from "pages/ContactsPage";
import { EspressoBarPage } from "pages/EspressoBarPage";
import { GastronomicShopPage } from "pages/GastronomicShopPage";
import { HackworkPage } from "pages/HackworkPage";
import { MainPage } from "pages/MainPage";
import { MerchPage } from "pages/MerchPage";
import { RestaurantPage } from "pages/RestaurantPage";
import { VineShopPage } from "pages/VineShopPage";

import Path from "../../constants/Routes";

import styles from "./index.module.scss";
import { RootState } from "store/store";
import {
  defaultIsRouteFromMenu,
  defaultRouteTo,
  defaultScrollTo,
} from "store/mainSlice";

const Routing = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { isMenu, routeTo } = useSelector((state: RootState) => state.main);

  const fadeAnimationOverlay = {
    enter:
      routeTo === "vine"
        ? styles.section_enterVine
        : routeTo === "batya"
        ? styles.section_enterBatya
        : styles.section_enter,

    enterActive:
      routeTo === "vine"
        ? styles.section_enterVine_active
        : routeTo === "batya"
        ? styles.section_enterBatya_active
        : styles.section_enter_active,

    exit:
      routeTo === "vine"
        ? styles.section_exitVine
        : routeTo === "batya"
        ? styles.section_exitBatya
        : styles.section_exit,

    exitActive:
      routeTo === "vine"
        ? styles.section_exitVine_active
        : routeTo === "batya"
        ? styles.section_exitBatya_active
        : styles.section_exit_active,
  };

  const fadeAnimationOverlayFromMenu = {
    enter: styles.section_enterMenu,
    enterActive: styles.section_enterMenu_active,
    exit: styles.section_exitMenu,
    exitActive: styles.section_exitMenu_active,
  };

  return (
    <TransitionGroup>
      <CSSTransition
        key={location.pathname}
        classNames={
          isMenu ? fadeAnimationOverlayFromMenu : fadeAnimationOverlay
        }
        transition={1500}
        timeout={1500}
        onExited={() => {
          dispatch(defaultRouteTo());
          dispatch(defaultScrollTo());
          dispatch(defaultIsRouteFromMenu());
        }}
      >
        <Routes location={location}>
          <Route path={Path.home} element={<MainPage />} />
          <Route path={Path.restaurant} element={<RestaurantPage />} />
          <Route path={Path.espresso_bar} element={<EspressoBarPage />} />
          <Route path={Path.vine_shop} element={<VineShopPage />} />
          <Route
            path={Path.gastronomic_shop}
            element={<GastronomicShopPage />}
          />
          <Route path={Path.cafe} element={<CafePage />} />
          <Route path={Path.merch} element={<MerchPage />} />
          <Route path={Path.career} element={<CareerPage />} />
          <Route path={Path.hackwork} element={<HackworkPage />} />
          <Route path={Path.contacts} element={<ContactsPage />} />
          <Route path="*" element={<Navigate to={Path.home} replace />} />
        </Routes>
      </CSSTransition>
    </TransitionGroup>
  );
};

export default Routing;
