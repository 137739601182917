import { FC, useEffect, useState } from 'react';

import styles from './index.module.scss';

import cn from 'classnames';

import { useField } from 'formik';
import { Input } from 'components/UI/Input';

interface SocialsInputProps {
  name: string;
  type: string;
  placeholder: string;
  className?: string;
  contact: any;
  setContact: any;
  formType: 'red' | 'black' | 'cold' | undefined;
}

const SocialsInput: FC<SocialsInputProps> = ({
  className,
  placeholder,
  name,
  type,
  setContact,
  contact,
  formType,
}) => {
  const socials = ['telegram', 'whatsapp'];
  const [telegramValue, setTelegramValue] = useState<string>('');
  const [field, meta, helpers] = useField(name);

  useEffect(() => {
    setTelegramValue(field.value);
    helpers.setValue(telegramValue);
    if (contact !== 'telegram') {
      helpers.setValue('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contact]);

  const readOnly = (value: string) => {
    if (contact !== 'telegram') {
      return true;
    }
    return false;
  };

  return (
    <div className={cn(className, styles.socials)}>
      <p className={styles.socials__text}>Прошу связаться со мной*</p>
      <div
        className={cn(styles.socials__block, {
          [styles.socials__block_light]:
            formType === 'red' || formType === 'black',
          [styles.socials__block_dark]: formType === 'cold',
        })}
      >
        {socials.map((social: string, index: number) => (
          <span
            className={cn(styles.socials__block_item, {
              [styles.socials__block_item_active]: contact === social,
              [styles.socials__block_item_disabled]: contact !== social,
            })}
            key={index}
            onClick={() => setContact(social)}
          >
            {social}
          </span>
        ))}
      </div>
      <Input
        name={name}
        type={type}
        placeholder={placeholder}
        readOnly={readOnly(contact)}
        className={styles.socials__input}
      />
    </div>
  );
};

export { SocialsInput };
