import ReactDom from 'react-dom';
import { useEffect, useState } from 'react';

import styles from './index.module.scss';

import { ReactComponent as IconOpened } from '../../assets/images/icon_burger_light_close.svg';

import { Image } from '../Image';

import cn from 'classnames';

import { splitText } from 'utils/splitText';

import { CSSTransition } from 'react-transition-group';
import { FormModal } from 'layouts/FormModal';
import { useModal } from 'hooks/useModal';

interface ModalProps {
	hide: any;
	data: any;
	modalType?: 'lavka' | 'magaz';
}

const Modal = ({ hide, data, modalType }: ModalProps) => {
	const { isShowing, toggle } = useModal();
	const [isShowDescr, setIsShowDescr] = useState<boolean>(false);

	const features = [
		{
			title: data?.text_block1 !== '' ? data.text_block1 : undefined,
			info: data?.text_block2 !== '' ? data.text_block2 : undefined
		},
		{
			title: data?.text_block3 !== '' ? data.text_block3 : undefined,
			info: data?.text_block4 !== '' ? data.text_block4 : undefined
		}
	];

	useEffect(() => {
		features.forEach(item => {
			(!!item.title || !!item.info) && setIsShowDescr(true);
		});
	}, [features]);

	return ReactDom.createPortal(
		<>
			<div
				className={styles.modal}
				aria-modal
				aria-hidden
				tabIndex={-1}
				role='dialog'
			>
				{!!data.image?.original && (
					<Image
						src={data.image.original}
						alt={data.image.title}
						containerClass={styles.modal__container}
						imageClass={styles.modal__container_image}
					/>
				)}

				<div className={cn(styles.modal__content, styles.content)}>
					<div className={styles.content__scroll}>
						{!!data.title && (
							<h2 className={styles.content__title}>{data.title}</h2>
						)}

						{!!data.description && (
							<blockquote
								className={styles.content__quote}
								dangerouslySetInnerHTML={{
									__html: data.description
								}}
							/>
						)}
						{modalType === 'magaz' && (
							<>
								{isShowDescr && (
									<div className={cn(styles.content__feature, styles.feature)}>
										<div className={styles.feature__block}>
											{features.map((feature: any, index: number) => (
												<div className={styles.feature__block_item} key={index}>
													{!!feature.title && (
														<h3 className={styles.feature__block_item_title}>
															{feature.title}
														</h3>
													)}

													{!!feature.info && (
														<p className={styles.feature__block_item_info}>
															{feature.info}
														</p>
													)}
												</div>
											))}
										</div>
									</div>
								)}

								{!!data?.dopImage?.original && (
									<Image
										src={data.dopImage.original}
										alt={data.dopImage.title}
										containerClass={styles.feature__container}
										imageClass={styles.feature__container_image}
									/>
								)}

								{!!data?.text_block5 && (
									<p
										className={styles.content__info}
										dangerouslySetInnerHTML={{
											__html: `${splitText(data.text_block5)}`
										}}
									></p>
								)}
							</>
						)}
						{modalType === 'lavka' && (
							<div className={styles.content__block}>
								{!!data.text_block1 && (
									<p className={styles.content__block_text}>
										{data.text_block1}
									</p>
								)}
								{!!data.text_block3 && (
									<p className={styles.content__block_text}>
										{data.text_block3}
									</p>
								)}
							</div>
						)}
						<div className={styles.content__link} onClick={toggle}>
							{modalType === 'magaz' && 'Задать вопрос по магазину'}
							{modalType === 'lavka' && 'Задать вопрос по лавке'}
						</div>
					</div>
				</div>
				<button className={styles.modal__close} onClick={hide}>
					<IconOpened />
				</button>
			</div>
			<CSSTransition
				in={isShowing}
				timeout={1300}
				classNames={{
					enterActive: styles.modal_enter_active,
					enterDone: styles.modal_enter_done,
					exitActive: styles.modal_exit_active,
					exit: styles.modal_exit
				}}
				mountOnEnter
				unmountOnExit
			>
				<FormModal
					hide={toggle}
					extraHide={hide}
					modalType={modalType}
					formType={'red'}
					formName={'notify'}
					formTitle={'Задать вопрос'}
				/>
			</CSSTransition>
		</>,
		document.getElementById('root-modal') as HTMLElement
	);
};

export { Modal };
