import styles from './index.module.scss';

import cn from 'classnames';
import React, { ForwardedRef } from 'react';

interface TitleBlockProps {
  children: React.ReactNode;
  className?: string;
  type?: string;
}

const TitleBlock = React.forwardRef(
  (
    { children, className, type }: TitleBlockProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <>
        {type === 'h1' ? (
          <h1
            className={cn(styles.page_title, className)}
            ref={ref ? ref : null}
          >
            {children}
          </h1>
        ) : type === 'h4' ? (
          <h4
            className={cn(styles.page_title, className)}
            ref={ref ? ref : null}
          >
            {children}
          </h4>
        ) : (
          <h2
            className={cn(styles.page_title, className)}
            ref={ref ? ref : null}
          >
            {children}
          </h2>
        )}
      </>
    );
  }
);

export { TitleBlock };
