import styles from './index.module.scss';

import cn from 'classnames';

import { Link } from 'react-router-dom';

import { NavItem } from '../NavItem';
import { useWindowWidth } from '../../../../hooks/useWindowWidth';

import { Image } from '../../../../components/Image';

import { useState } from 'react';

const Section = ({ data, className, type }: any) => {
	const windowWidth = useWindowWidth();
	const [currentIndex, setCurrentIndex] = useState<number | null>();

	return (
		<section
			className={cn(styles.section, {
				[styles.vine_cheese]: className === 'vine_cheese',
				[styles.batya29]: className === 'batya29'
			})}
			onMouseLeave={(evt: any) => {
				if (
					!evt.target.className.includes('header') &&
					!evt.target.className.includes('nav')
				) {
					setCurrentIndex(null);
				}
			}}
		>
			<nav
				className={cn({
					[styles.vine_cheese__nav]: className === 'vine_cheese',
					[styles.batya29__nav]: className === 'batya29'
				})}
			>
				{!!data?.place.length &&
					data.place.map((navItem: any, index: number) => (
						<NavItem
							title={navItem.title}
							links={navItem.links}
							pdf={navItem.pdf}
							className={className}
							type={type}
							key={index}
							onMouseEnter={() => {
								setCurrentIndex(index);
							}}
						/>
					))}
			</nav>
			<div
				className={cn(styles.contacts, {
					[styles.vine_cheese__contacts]: className === 'vine_cheese',
					[styles.batya29__contacts]: className === 'batya29'
				})}
			>
				{windowWidth && windowWidth > 743 && (
					<ul
						className={cn(styles.socials, {
							[styles.vine_cheese__socials]: className === 'vine_cheese',
							[styles.batya29__socials]: className === 'batya29'
						})}
					>
						{!!data?.socials.length &&
							data.socials.map((social: any, index: number) => (
								<li className={cn('link', styles.socials__item)} key={index}>
									{social.link !== '' && (
										<a
											href={social.link}
											target={'_blank'}
											className={cn('link', styles.socials__item_link)}
											rel='noreferrer'
										>
											{social.name}
										</a>
									)}
								</li>
							))}
					</ul>
				)}
				<div className={cn('link', styles.contacts__link)}>
					<a href={`tel:${data.contact.name}`}>+{data.contact.name}</a>
				</div>
			</div>
			{!!data?.images.length &&
				data.images.map((item: any, index: number) => (
					<Image
						src={item}
						alt={'Картинка при наведении'}
						containerClass={cn(styles.section__container, {
							[styles.section__container_active]: currentIndex === index,
							[styles.section__container_inActiveCafe]:
								type === 'vine' &&
								(currentIndex === undefined || currentIndex === null),
							[styles.section__container_inActiveBatya]:
								type === 'batya' &&
								(currentIndex === undefined || currentIndex === null)
						})}
						imageClass={styles.section__container_image}
						key={index}
					/>
				))}
		</section>
	);
};

export { Section };
