const envRoute = `${process.env.PUBLIC_URL}`;

const checkSlash = (url: any) => {
  if (!!url && typeof url === "string") {
    return url.substr(-1) !== "/" ? url + "/" : url;
  }
  return undefined;
};

const Routes = {
  home: `${checkSlash(envRoute) || '/'}`,
  restaurant: `${checkSlash(envRoute) || "/"}restaurant`,
  espresso_bar: `${checkSlash(envRoute) || "/"}espresso_bar`,
  vine_shop: `${checkSlash(envRoute) || "/"}vine_shop`,
  gastronomic_shop: `${checkSlash(envRoute) || "/"}gastronomic_shop`,
  cafe: `${checkSlash(envRoute) || "/"}cafe`,
  merch: `${checkSlash(envRoute) || "/"}merch`,
  career: `${checkSlash(envRoute) || "/"}career`,
  hackwork: `${checkSlash(envRoute) || "/"}hackwork`,
  contacts: `${checkSlash(envRoute) || "/"}contacts`,
};

export default Routes;
