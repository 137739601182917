import { useState } from 'react';

import styles from './index.module.scss';

import cn from 'classnames';

import { Button } from 'components/UI/Button';
import { Input } from 'components/UI/Input';
import { Textarea } from 'components/UI/Textarea';

import { SocialsInput } from 'components/FormFields/SocialsInput';

import { Formik, Form, FormikErrors } from 'formik';

import { useFormMutation } from 'store/formAPISlice';

import {
  formattedPhoneInputValue,
  handlePhoneInput,
  handlePhonePaste,
  normalizePhoneNumber,
} from 'utils/inputNumberMask';

import { handleGuestsInput } from 'utils/inputGuestsMask';

import {
  formattedTimeInputValue,
  handleTimeInput,
  handleTimePaste,
} from 'utils/inputTimeMask';
import {
  handleDateInput,
  formattedDateInputValue,
  handleDatePaste,
} from 'utils/inputDateMask';
import { useLocation } from 'react-router-dom';
import { getNumberOfRoute } from 'utils/getNumberOfRoute';

import Routes from '../../../constants/Routes';
import { checkDayInMonth } from 'utils/checkDayInMonth';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';

interface FormProps {
  className?: string;
  formType: 'red' | 'black' | 'cold' | undefined;
  hide?: any;
}

interface FormValues {
  type: string;
  fio: string;
  phone: string;
  guests: string;
  date: string;
  time: string;
  social: string;
  text: string;
}

const BookingForm = ({ className, formType, hide }: FormProps) => {
  const data = useSelector((state: RootState) => state.data.data);
  const [form, { isSuccess, isError }] = useFormMutation();
  const [contact, setContact] = useState<string>('telegram');

  const location = useLocation();
  const [isSend, setIsSend] = useState<boolean>(false);

  const validate = (values: FormValues) => {
    let errors: FormikErrors<FormValues> = {};

    const nameRegex = /^[a-zA-Zа-яёА-ЯЁ\s]+$/u;
    const phoneRegex =
      /^(\+7|7|8)?[\s]?\(?[489][0-9]{2}\)?[\s]?[0-9]{3}[\s]?[0-9]{2}[\s]?[0-9]{2}/;
    // const guestRegex = /^[0-4][0-9]|5[0]$/;
    const timeRegexCafe = /^(0[9]|1[0-9]|2[0-2]):[0-5][0-9]$/;
    const timeRegexRest = /^(1[0-9]|2[0-2]):[0-5][0-9]$/;
    const dateRegex = /(^0[1-9]|[12][0-9]|3[01]).(0[1-9]|1[0-2])/;
    const telegramRegex = /.*\B@(?=\w{5,32}\b)[a-zA-Z0-9]+(?:_[a-zA-Z0-9]+)*.*/;

    if (!values.fio) {
      errors.fio = 'Обязательное поле';
    } else if (!nameRegex.test(values.fio)) {
      errors.fio = 'Введите корректное имя';
    }

    if (!values.phone) {
      errors.phone = 'Обязательное поле';
    } else if (!phoneRegex.test(values.phone)) {
      errors.phone = 'Введите телефон в верном формате';
    }

    if (!values.guests) {
      errors.guests = 'Обязательное поле';
    }
    // } else if (!guestRegex.test(values.guests)) {
    //   //Уточнить сколько гостей или убрать доп валидацию//
    //   errors.guests = 'Количество гостей: 1 - 50';
    // }

    if (!values.time) {
      errors.time = 'Обязательное поле';
    } else if (
      !timeRegexCafe.test(values.time) &&
      location.pathname === Routes.cafe
    ) {
      errors.time = 'Время работы кафе 09:00 - 23:00';
    } else if (
      !timeRegexRest.test(values.time) &&
      location.pathname === Routes.restaurant
    ) {
      errors.time = 'Время работы кафе 10:00 - 23:00';
    }

    if (!values.date) {
      errors.date = 'Обязательное поле';
    } else if (!dateRegex.test(values.date)) {
      errors.date = 'Введите дату в корректном формате';
    } else if (checkDayInMonth(values.date)) {
      errors.date = 'Введите дату в корректном формате';
    }

    if (contact === 'telegram') {
      if (!values.social) {
        errors.social = 'Обязательное поле';
      } else if (!telegramRegex.test(values.social)) {
        errors.social = 'Введите id в формате @username';
      }
    }

    return errors;
  };
  return (
    <>
      <Formik
        initialValues={{
          type: '2',
          fio: '',
          phone: '',
          guests: '',
          date: '',
          time: '',
          social: '',
          text: '',
        }}
        validateOnMount
        validateOnChange
        validate={validate}
        onSubmit={async (values, { resetForm }) => {
          if (!isSend) {
            setIsSend(true);

            const formdata = new FormData();
            const today = new Date();

            formdata.append('type', values.type);
            formdata.append('object_id', getNumberOfRoute(location.pathname));
            formdata.append('fio', values.fio);
            formdata.append('phone', normalizePhoneNumber(values.phone));
            formdata.append('guests', values.guests);
            formdata.append('date', `${values.date}.${today.getFullYear()}`);
            formdata.append('time', values.time);
            formdata.append('social', values.social);
            formdata.append('text', values.text);
            try {
              await form(formdata);
              setTimeout(() => {
                hide();
                resetForm();
              }, 1000);
            } catch (error: any) {
              setTimeout(() => {
                hide();
                resetForm();
              }, 1000);
            }
          }

        }}
      >
        <Form className={cn(styles.form, className)}>
          <h2 className={styles.form__title}>
            <span>Бронирование</span>
          </h2>
          <span className={styles.form__point}>*- Обязательное поле</span>
          <div className={styles.form__block}>
            <div className={styles.form__group}>
              <Input
                name='fio'
                type='text'
                placeholder='Имя*'
                className={styles.form__group_name}
              />
              <Input
                name='phone'
                type='tel'
                placeholder={'+7*'}
                handleInput={handlePhoneInput}
                formattedInputValue={formattedPhoneInputValue}
                handlePaste={handlePhonePaste}
                maxLength={18}
                className={styles.form__group_number}
              />
            </div>
            <div className={styles.form__group}>
              <Input
                name='guests'
                type='text'
                handleInput={handleGuestsInput}
                placeholder='Количество гостей*'
                className={styles.form__group_guests}
                maxLength={2}
              />
              <Input
                name='time'
                type='text'
                handleInput={handleTimeInput}
                formattedInputValue={formattedTimeInputValue}
                handlePaste={handleTimePaste}
                placeholder={'Время посещения*'}
                maxLength={5}
                className={styles.form__group_time}
              />
              <Input
                name='date'
                type='text'
                handleInput={handleDateInput}
                formattedInputValue={formattedDateInputValue}
                handlePaste={handleDatePaste}
                placeholder={'Дата посещения*'}
                className={styles.form__group_date}
              />
            </div>
            <div className={styles.form__group}>
              <SocialsInput
                name='social'
                type='text'
                placeholder={'id telegram'}
                className={styles.form__group_socials}
                contact={contact}
                setContact={setContact}
                formType={formType}
              />
            </div>
            <div className={styles.form__group}>
              <Textarea
                name='text'
                placeholder={'Комментарий'}
                className={styles.form__group_text}
              />
            </div>
          </div>
          <footer className={cn(styles.form__footer, styles.footer)}>
            <p className={styles.footer__text}>
              нажимая на кнопку “отправить”, вы соглашаетесь на{' '}
              <a href={!!data.config.file_policy_1 ? data.config.file_policy_1 : "#"} target={"_blank"} className={styles.footer__text_link}>
                обработку персональных данных
              </a>
            </p>
            <Button className={styles.footer__button} type={'submit'} disabled={isSend}>
              Отправить
            </Button>
          </footer>
        </Form>
      </Formik>
      {isSuccess && (
        <h3
          className={cn(styles.form__success, {
            [styles.form__success_red]: formType === 'red',
            [styles.form__success_black]: formType === 'black',
            [styles.form__success_cold]: formType === 'cold',
          })}
        >
          Отправлено
        </h3>
      )}
      {isError && (
        <h3
          className={cn(styles.form__success, {
            [styles.form__success_red]: formType === 'red',
            [styles.form__success_black]: formType === 'black',
            [styles.form__success_cold]: formType === 'cold',
          })}
        >
          Произошла ошибка
        </h3>
      )}
    </>
  );
};

export { BookingForm };
