import styles from './index.module.scss';

import cn from 'classnames';

import { TitleBlock } from '../TitleBlock';

import { ProductsListBlockProps } from './types';

import { useEffect, useRef } from 'react';
import useIntersectionObserver from 'hooks/useIntersectionObserver';

const MenuItem = ({ item }: any) => {
  const ref = useRef<HTMLLIElement | null>(null);
  const entry = useIntersectionObserver(ref, {});
  const isVisible = !!entry?.isIntersecting;

  return (
    <li
      className={cn(styles.menu__card, {
        [styles.menu__card_active]: isVisible,
      })}
      ref={ref}
    >
      <a href={item.url} target={"_blank"}>{item.name}</a>
    </li>
  );
};

const MenuBlock = ({ data, className, single }: ProductsListBlockProps) => {
  //Menu
  const refMenu = useRef<HTMLDivElement | null>(null);
  const entryMenu = useIntersectionObserver(refMenu, {});
  const isVisibleMenu = !!entryMenu?.isIntersecting;

  //Cards
  const refCards = useRef<HTMLUListElement | null>(null);
  const entryCards = useIntersectionObserver(refCards, {});
  const isVisibleCards = !!entryCards?.isIntersecting;

  return (
    <div
      className={cn(styles.menu, className, {
        [styles.menu_active]: isVisibleMenu,
      })}
      ref={refMenu}
    >
      {!!data?.title && (
        <TitleBlock className={styles.menu__title}>{data.title}</TitleBlock>
      )}
      <ul
        className={cn(styles.menu__cards, {
          [styles.menu__cards_single]: single,
          [styles.menu__cards_active]: isVisibleCards,
        })}
        ref={refCards}
      >
        {!!data?.list.length && data.list.map((item: string, index: number) => (
          <MenuItem key={index} item={item} />
        ))}
      </ul>
    </div>
  );
};

export { MenuBlock };
