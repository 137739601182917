import styles from './index.module.scss';

import { Layout } from 'components/Layout';
import { LoaderSvg } from 'assets/icons';

import { useEffect, useState } from 'react';

const LoadingPage = () => {
  const [percent, setPercent] = useState<number>(0);
  const [speed, setSpeed] = useState<number>(70);

  useEffect(() => {
    const interval = setInterval(() => {
      setPercent((oldvalue) => {
        let newValue = oldvalue + 1;
        if (newValue > 97) {
          clearInterval(interval);
        }
        return newValue;
      });
    }, speed);
  }, [speed]);

  useEffect(() => {
    if (percent >= 30) {
      setSpeed(40);
    }
    if (percent >= 50) {
      setSpeed(20);
    }
  }, [percent]);

  return (
    <Layout className={styles.page}>
      <div className={styles.page__loader}>
        <LoaderSvg classname={styles.page__img} />
      </div>
    </Layout>
  );
};

export { LoadingPage };
