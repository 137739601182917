import styles from "./index.module.scss";

import { ReactComponent as PDFIcon } from "../../../../assets/images/icon_pdf_light.svg";

import { NavItemProps } from "../../types";

import cn from "classnames";
import { useWindowWidth } from "../../../../hooks/useWindowWidth";
import CustomLink from "components/CustomLink";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import {
  setIsRouteFromMenuVine,
  setIsRouteFromMenuBatya,
} from "store/mainSlice";
import { Link } from "react-router-dom";
import Routes from "constants/Routes";

const NavItem = ({
  title,
  links,
  pdf,
  type,
  className,
  onMouseEnter,
  onMouseLeave,
}: NavItemProps) => {
  const dispatch = useDispatch();
  const windowWidth = useWindowWidth();
  const { isMenu } = useSelector((state: RootState) => state.main);

  const handleClick = () => {
    if (isMenu) {
      type === "vine" && dispatch(setIsRouteFromMenuVine());
      type === "batya" && dispatch(setIsRouteFromMenuBatya());
    }
  };

  return (
    <div
      className={cn(styles.nav_item, {
        [styles.vine_cheese__nav_item]: className === "vine_cheese",
        [styles.batya29__nav_item]: className === "batya29",
      })}
    >
      <div
        className={styles.nav_item__container}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <CustomLink
          href={title.href}
          name={title.name}
          className={styles.nav_item__title}
          onClick={handleClick}
          white
        />

        {!!links?.length && (
          <ul className={styles.nav_item__list}>
            {links?.map((item: any, index: number) => (
              <li className={styles.nav_item__list_item} key={index}>
                {pdf ? (
                  <a target={"_blank"} href={item.url}>{item.name}</a>
                ) : (
                  <Link to={`${Routes.merch}#${item.id}`}>{item.label}</Link>
                )}
              </li>
            ))}
          </ul>
        )}
      </div>
      {windowWidth && windowWidth > 1130 && pdf && (
        <div className={styles.nav_item__logo}>
          <PDFIcon />
        </div>
      )}
    </div>
  );
};

export { NavItem };
