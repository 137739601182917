import { Layout } from '../../components/Layout';
import { PageFooter } from '../../components/PageFooter';
import { PageContent } from '../../components/PageContent';

import { TitleBlock } from '../../components/PageBlocks/TitleBlock';
import { NextPageBlock } from '../../components/PageBlocks/NextPageBlock';
import { MenuBlock } from '../../components/PageBlocks/MenuBlock';
import { AboutBlock } from './sections/AboutBlock';

import styles from './index.module.scss';

import Routes from '../../constants/Routes';
import { useWindowWidth } from 'hooks/useWindowWidth';

import { RootState } from 'store/store';
import { useSelector } from 'react-redux';

const pageData = {
  title: 'Эспрессо бар',
  footer: {
    link: ['Задать вопрос по эспрессо-бару'],
    formTitle: 'Задать вопрос',
  },
  next: {
    name: 'Винный магазин',
    link: `${Routes.vine_shop}`,
  },
};

const EspressoBarPage = () => {
  const windowWidth = useWindowWidth();

  const esp = useSelector((state: RootState) => state.data.data.esp);
  const menu = useSelector((state: RootState) => state.data.data);

  const menuData = {
    list: menu.config.esp,
  };

  const aboutData = {
    main: {
      img: {
        href: esp.images['group-slide'][0].original,
        alt: esp.images['group-slide'][0].description,
      },
      info: esp.images['group-slide'][0].title,
      info_extra: esp.images['group-slide'][0].description,
    },
    img: esp.images['group-image'],
  };

  return (
    <Layout className={styles.page}>
      <PageContent className={styles.page__content}>
        <TitleBlock className={styles.page__title} type='h1'>
          {esp.name}
        </TitleBlock>
        <MenuBlock data={menuData} className={styles.page__menu} single />
        <AboutBlock data={aboutData} />
        <NextPageBlock data={pageData.next} />
      </PageContent>
      {windowWidth && windowWidth > 1279 && (
        <PageFooter
          data={pageData.footer}
          className={styles.page__footer}
          formType={'red'}
        />
      )}
    </Layout>
  );
};

export { EspressoBarPage };
