import styles from './index.module.scss';

import cn from 'classnames';

interface LayoutProps {
  children: React.ReactNode;
  className?: string;
}

const Layout = ({ children, className }: LayoutProps) => {
  return <div className={cn(styles.container, className)}>{children}</div>;
};

export { Layout };
