import styles from './index.module.scss';

import cn from 'classnames';

import { Image } from '../../../../components/Image';
import { useRef } from 'react';
import useIntersectionObserver from 'hooks/useIntersectionObserver';

interface AboutBlockProps {
  data: {
    main: {
      img: {
        href: string;
        alt: string;
      };
      info: string;
      info_extra: string;
    };
    img: {
      description: string;
      group_name: string;
      id: number;
      id_model: number;
      is_main: number;
      key: string;
      marker: string;
      order_image: number;
      original: string;
      resize1: string;
      resize2: string;
      resize3: string;
      text_block1: boolean;
      text_block2: boolean;
      title: string;
      type: number;
    }[];
  };
}

const ImageBlock = ({ item }: any) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(ref, {});
  const isVisible = !!entry?.isIntersecting;

  return (
    <Image
      src={item.original}
      alt={item.title}
      containerClass={cn(styles.list__container, {
        [styles.list__container_active]: isVisible,
      })}
      imageClass={styles.list__container_image}
      ref={ref}
    />
  );
};

const AboutBlock = ({ data }: AboutBlockProps) => {
  const textTransform = (value: string) => {
    const splittedValues = value.split('.');
    const newArray: string[] = [];
    splittedValues.forEach((item: string, index: number) => {
      newArray.push(
        `<span style="transition-delay: ${index * 0.2}s">${item.trim()}${
          index !== splittedValues.length - 1 ? '.' : ''
        }</span>`
      );
    });
    return newArray.join('');
  };

  //MainImage//
  const refMainImage = useRef<HTMLDivElement | null>(null);
  const entryMainImage = useIntersectionObserver(refMainImage, {});
  const isVisibleMainImage = !!entryMainImage?.isIntersecting;

  //MainInfo//
  const refMainInfo = useRef<HTMLParagraphElement | null>(null);
  const entryMainInfo = useIntersectionObserver(refMainInfo, {});
  const isVisibleMainInfo = !!entryMainInfo?.isIntersecting;

  //ExtraInfo//
  const refExtraInfo = useRef<HTMLParagraphElement | null>(null);
  const entryExtraInfo = useIntersectionObserver(refExtraInfo, {});
  const isVisibleExtraInfo = !!entryExtraInfo?.isIntersecting;

  return (
    <div className={styles.about}>
      <div className={cn(styles.about__main, styles.main)}>
        <Image
          src={data.main.img.href}
          alt={data.main.img.alt}
          containerClass={cn(styles.main__container, {
            [styles.main__container_active]: isVisibleMainImage,
          })}
          imageClass={styles.main__container_image}
          ref={refMainImage}
        />
        <div className={styles.main__info}>
          <p
            className={cn(styles.main__info_main, {
              [styles.main__info_main_active]: isVisibleMainInfo,
            })}
            dangerouslySetInnerHTML={{
              __html: `${textTransform(data.main.info)}`,
            }}
            ref={refMainInfo}
          ></p>
          <p
            className={cn(styles.main__info_extra, {
              [styles.main__info_extra_active]: isVisibleExtraInfo,
            })}
            dangerouslySetInnerHTML={{
              __html: `${textTransform(data.main.info_extra)}`,
            }}
            ref={refExtraInfo}
          ></p>
        </div>
      </div>
      <div className={cn(styles.about__list, styles.list)}>
        {!!data?.img.length && data.img.map((item: any) => (
          <ImageBlock item={item} key={item.id} />
        ))}
      </div>
    </div>
  );
};

export { AboutBlock };
