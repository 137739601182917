import { IAdvBlock } from "./types";
import cn from "classnames";

import styles from './index.module.scss';
import { Image } from '../../../../Image';
import { useRef } from "react";
import useIntersectionObserver from "hooks/useIntersectionObserver";


const AdvBlock = ({classname, text, src}: IAdvBlock) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(ref, {});
  const isVisible = !!entry?.isIntersecting;

    return  (
        <div
        className={cn(classname, styles.content, {
          [styles.content_visible]: isVisible
        })}
        ref={ref}
      >
        <Image
          src={src}
          alt={''}
          imageClass={styles.content__container_image}
          containerClass={cn(styles.content__container, {
            [styles.content__container_active]: isVisible
          })}
        />
        <p
          className={cn(styles.content__info, {
            [styles.content__info_active]: isVisible
          })}
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        ></p>
      </div>
    )
}

export default AdvBlock;