import { createContext, useContext } from "react";

interface IHeaderInvert {
  invertProcentLogoRight: number;
  setInvertProcentLogoRight: (precent: number) => void;
}

export const HeaderInvertContext = createContext<IHeaderInvert>({
  invertProcentLogoRight: 0,
  setInvertProcentLogoRight: () => {},
});

export const useHeaderInvertContext = () => useContext(HeaderInvertContext);
