import styles from "./index.module.scss";
import { useEffect } from "react";

import cn from "classnames";
import { useHorizontalScroll } from "../../hooks/useHorizontalScroll";

interface PageContentProps {
  children: React.ReactNode;
  className?: string;
  getElement?: (node: HTMLDivElement) => void;
}

const PageContent = ({ children, className, getElement }: PageContentProps) => {
  const wheelRef = useHorizontalScroll();

  useEffect(() => {
    getElement && wheelRef.current && getElement(wheelRef.current);
  }, [wheelRef]); // eslint-disable-line

  return (
    <main ref={wheelRef} className={cn(styles.content, className)}>
      <section className={styles.content__section}>{children}</section>
    </main>
  );
};

export { PageContent };
