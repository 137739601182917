import {
	ArrowSmall,
	CloseSliderIcon,
	MainButtonCafe,
	MainPageLogoCafe
} from 'assets/icons';
import cn from 'classnames';
import CustomLink from 'components/CustomLink';
import Routes from 'constants/Routes';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import Slider from 'react-slick';
import { RootState } from 'store/store';
import Arrow from '../Arrow';
import SlickSlide from '../SlickSlide';

import cafePlug from '../../../../assets/images/cafe_static.jpg';

import styles from './index.module.scss';
import { ICafeBlock } from './types';

const CafeBlock = ({
	isHoverCafe,
	isfullScreenBatya,
	isfullScreenCafe,
	handleHoverCafe,
	innerRef,
	data,
	cnBlock,
	cnArea,
	cnFooter,
	cnStatic,
	handleAreaEnter,
	handleAreaLeave,
	handleAreaClick,
	handleMouseLeave,
	handleMouseEnter
}: ICafeBlock) => {
	const mainData = useSelector((state: RootState) => state.data.data);

	const [staticName, setStaticName] = useState<string | null>(null);
	const [slideIndex, setSlideIndex] = useState<number>(1);
	const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0);

	return (
		<div className={cnBlock} onMouseEnter={handleHoverCafe}>
			{!!data?.cafeSlider ? (
				<Slider
					infinite={true}
					autoplaySpeed={2000}
					// fade={true}
					arrows={false}
					className={styles.slider}
					ref={innerRef}
					afterChange={(currentSlide: number) => {
						setSlideIndex(currentSlide + 1);
						setCurrentSlideIndex(currentSlide);
					}}
					responsive={[
						{
							breakpoint: 1279,
							settings: {
								fade: !isfullScreenCafe,
								vertical: isfullScreenCafe,
								verticalSwiping: isfullScreenCafe
							}
						}
					]}
				>
					{data.cafeSlider.map((n: any, i: number) => (
						<SlickSlide
							key={i}
							className={styles.slider__slide}
							data={n}
							sliderRef={innerRef}
							isfullScreen={isfullScreenCafe}
							handleMouseLeave={handleMouseLeave}
							handleMouseEnter={handleMouseEnter}
							handleAreaClick={handleAreaClick}
							cafe
						/>
					))}
				</Slider>
			) : (
				<div className={styles.slider__plug}>
					<img src={cafePlug} alt='' />
				</div>
			)}

			{!!data?.cafeSlider && (
				<div
					className={cn(styles.slideDescr, {
						[styles.slideDescr_show]: isfullScreenCafe
					})}
				>
					{!!data?.cafeSlider.length &&
						data.cafeSlider.map((n: any, i: number) => (
							<div
								key={i}
								className={cn(styles.slideDescr__wrap, {
									[styles.slideDescr__wrap_show]: currentSlideIndex === i
								})}
							>
								<h4 className={styles.slideDescr__title}>{n.title}</h4>
								<div className={styles.slideDescr__text}>
									<p>{n.description}</p>
								</div>
							</div>
						))}
				</div>
			)}

			{!!data?.cafeSlider && data?.cafeSlider.length > 1 && (
				<div
					className={cn(styles.pagination, {
						[styles.pagination_show]: isfullScreenCafe
					})}
				>
					<span>
						{slideIndex}/{data.cafeSlider.length}
					</span>
				</div>
			)}

			<div className={cn(cnStatic, styles.static)}>
				{!!mainData?.rest?.images?.navigationStatic?.length && (
					<img
						src={mainData.rest.images.navigationStatic[0].original}
						alt=''
						className={cn(styles.static__img, {
							[styles.static__img_show]: staticName === 'rest'
						})}
					/>
				)}
				{!!mainData?.magaz?.images?.navigationStatic?.length && (
					<img
						src={mainData.magaz.images.navigationStatic[0].original}
						alt=''
						className={cn(styles.static__img, {
							[styles.static__img_show]: staticName === 'vine'
						})}
					/>
				)}
				{!!mainData?.esp?.images?.navigationStatic?.length && (
					<img
						src={mainData.esp.images.navigationStatic[0].original}
						alt=''
						className={cn(styles.static__img, {
							[styles.static__img_show]: staticName === 'esp'
						})}
					/>
				)}
				{!!mainData?.lavka?.images?.navigationStatic?.length && (
					<img
						src={mainData.lavka.images.navigationStatic[0].original}
						alt=''
						className={cn(styles.static__img, {
							[styles.static__img_show]: staticName === 'gastro'
						})}
					/>
				)}
			</div>

			<button
				onClick={handleAreaClick}
				className={cn(styles.sliderClose, {
					[styles.sliderClose_show]: isfullScreenCafe
				})}
			>
				<CloseSliderIcon />
			</button>

			{!!data?.cafeSlider && data?.cafeSlider.length > 1 && (
				<span
					className={cnArea}
					onMouseEnter={handleAreaEnter}
					onMouseLeave={handleAreaLeave}
					onClick={handleAreaClick}
				></span>
			)}

			<div
				className={cn(styles.footerXs, {
					[styles.footerXs_show]: !isHoverCafe,
					[styles.footerXs_hide]: isHoverCafe
				})}
			>
				<div className={cn(styles.footerXs__logo)}>
					<MainPageLogoCafe />
				</div>

				<div className={styles.footerXs__arrow}>
					<ArrowSmall />
				</div>
			</div>

			<div
				className={cn(cnFooter, styles.footer, {
					[styles.footer_hover]: isHoverCafe,
					[styles.footer_hide]: isfullScreenBatya || isfullScreenCafe
				})}
			>
				{!!data?.cafeSlider && data?.cafeSlider.length > 1 && (
					<div className={styles.footer__icon} onClick={handleAreaClick}>
						<MainButtonCafe />
					</div>
				)}
				<div className={cn(styles.footer__logo, styles.footer__logo_cafe)}>
					<MainPageLogoCafe />
				</div>
				<nav className={cn(styles.footer__nav, styles.nav)}>
					<ul className={styles.nav__list}>
						<li
							className={styles.nav__item}
							onMouseEnter={() => setStaticName('rest')}
							onMouseLeave={() => setStaticName(null)}
						>
							<CustomLink
								href={Routes.restaurant}
								name={'ресторан'}
								className={styles.nav__link}
							/>
						</li>
						<li
							className={styles.nav__item}
							onMouseEnter={() => setStaticName('vine')}
							onMouseLeave={() => setStaticName(null)}
						>
							<CustomLink
								href={Routes.vine_shop}
								name={'винный магазин'}
								className={styles.nav__link}
							/>
						</li>
						<li
							className={styles.nav__item}
							onMouseEnter={() => setStaticName('esp')}
							onMouseLeave={() => setStaticName(null)}
						>
							<CustomLink
								href={Routes.espresso_bar}
								name={'Эспрессо бар'}
								className={styles.nav__link}
							/>
						</li>
						<li
							className={styles.nav__item}
							onMouseEnter={() => setStaticName('gastro')}
							onMouseLeave={() => setStaticName(null)}
						>
							<CustomLink
								href={Routes.gastronomic_shop}
								name={'Гастрономическая лавка'}
								className={styles.nav__link}
							/>
						</li>
					</ul>
				</nav>

				<span
					className={cn(styles.footer__dividerCafe, {
						[styles.footer__dividerCafe_hover]: isHoverCafe
					})}
				></span>

				<div className={cn(styles.footer__socials, styles.socials)}>
					<ul className={styles.socials__list}>
						{!!mainData?.social.magaz.instagram && (
							<li className={styles.socials__item}>
								<a
									href={mainData?.social.magaz.instagram}
									target='_blank'
									className={styles.socials__link}
									rel='noreferrer'
								>
									instagram
								</a>
							</li>
						)}
						{!!mainData?.social.magaz.telegram && (
							<li className={styles.socials__item}>
								<a
									href={mainData?.social.magaz.telegram}
									target='_blank'
									className={styles.socials__link}
									rel='noreferrer'
								>
									telegram
								</a>
							</li>
						)}
						{!!mainData?.social.magaz.vkontakte && (
							<li className={styles.socials__item}>
								<a
									href={mainData?.social.magaz.vkontakte}
									target='_blank'
									className={styles.socials__link}
									rel='noreferrer'
								>
									vk
								</a>
							</li>
						)}
					</ul>
					{!!mainData?.config.poster_file_wine && (
						<CustomLink
							href={mainData.config.poster_file_wine}
							name={'афиша'}
							className={styles.socials__poster}
							hoverIsVisibleCafe
							download
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default CafeBlock;
