import { FC } from 'react';

import { Link } from "react-router-dom";
import cn from 'classnames';

import styles from './index.module.scss';
import { CustomHoverLeft, CustomHoverMid, CustomHoverRight } from "assets/icons";

interface ICustomLink {
    className?: string;
    href: string;
    name: string;
    onClick?: () => void;
    white?: boolean;
    black?: boolean;
    download?: boolean;
    hoverIsVisibleCafe?: boolean;
    hoverIsVisibleBatya?: boolean;
}

const CustomLink: FC<ICustomLink> = ({
    className,
    href,
    name,
    onClick,
    white,
    black,
    download = false,
    hoverIsVisibleCafe = false,
    hoverIsVisibleBatya = false,
}) => {
    return (

        <>
            {download ? (
                <a
                    href={href}
                    className={cn(className, styles.customLink, {
                        [styles.customLink_visibleCafe]: hoverIsVisibleCafe,
                        [styles.customLink_visibleBatya]: hoverIsVisibleBatya
                    })}
                    onClick={onClick}
                    target={"_blank"}
                >
                    <div className={styles.customLink__wrap}>
                        <div className={styles.customLink__wrapOverflow}>
                            <CustomHoverLeft
                                classname={cn(styles.customLink__hoverBg, styles.customLink__hoverBg_left)}
                                white={white}
                                black={black}
                            />
                            <CustomHoverMid
                                classname={cn(styles.customLink__hoverBg, styles.customLink__hoverBg_mid)}
                                white={white}
                                black={black}
                            />
                            <CustomHoverRight
                                classname={cn(styles.customLink__hoverBg, styles.customLink__hoverBg_right)}
                                white={white}
                                black={black}
                            />
                        </div>
                    </div>
                    <span>{name}</span>
                </a>
            ) : (
                <Link
                    to={href}
                    className={cn(className, styles.customLink, {
                        [styles.customLink_visibleCafe]: hoverIsVisibleCafe,
                        [styles.customLink_visibleBatya]: hoverIsVisibleBatya
                    })}
                    onClick={onClick}
                >
                    <div className={styles.customLink__wrap}>
                        <div className={styles.customLink__wrapOverflow}>
                            <CustomHoverLeft
                                classname={cn(styles.customLink__hoverBg, styles.customLink__hoverBg_left)}
                                white={white}
                                black={black}
                            />
                            <CustomHoverMid
                                classname={cn(styles.customLink__hoverBg, styles.customLink__hoverBg_mid)}
                                white={white}
                                black={black}
                            />
                            <CustomHoverRight
                                classname={cn(styles.customLink__hoverBg, styles.customLink__hoverBg_right)}
                                white={white}
                                black={black}
                            />
                        </div>
                    </div>
                    <span>{name}</span>
                </Link>
            )}
        </>
    )
}

export default CustomLink;