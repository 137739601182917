import { configureStore } from '@reduxjs/toolkit';

import allDataSlice from './allDataSlice';
import mainSlice from './mainSlice';
import ageReducer from './ageSlice';

export const store = configureStore({
  reducer: {
    data: allDataSlice,
    main: mainSlice,
    age: ageReducer,
  },
});


export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
