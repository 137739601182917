import { FC, useRef, useState } from "react";

import styles from "./index.module.scss";

import cn from "classnames";

import { Image } from "../../../../components/Image";

import { TitleBlock } from "../../../../components/PageBlocks/TitleBlock";
import { CSSTransition } from "react-transition-group";
import { useModal } from "hooks/useModal";
import { FormModal } from "layouts/FormModal";
import useIntersectionObserver from "hooks/useIntersectionObserver";
import { useWindowWidth } from "hooks/useWindowWidth";

interface MerchBlockProps {
  data: {
    title: string;
    description: {
      title: string;
      info: string;
    };
    shop: any[];
    outro: string;
  };
}

const MerchShop = ({ block, tovar, setCurrentMerch, toggle }: any) => {
  //Shop
  const refShop = useRef<HTMLDivElement | null>(null);
  const entryShop = useIntersectionObserver(refShop, { threshold: 0.0 });
  const isVisibleShop = !!entryShop?.isIntersecting;

  return (
    <>
        {!!tovar?.length && (
          <div
            className={cn(styles.shop__content, styles.content, {
              [styles.content_active]: isVisibleShop,
            })}
            ref={refShop}
          >
          <h4 className={styles.content__title}>{block?.title}</h4>
          <div className={styles.content__images}>
            {!!tovar.length && tovar[0].map((pic: any) => {
              return (
                <MerchImage
                  pic={pic}
                  block={block}
                  setCurrentMerch={setCurrentMerch}
                  toggle={toggle}
                  key={pic.id}
                />
              );
            })}
          </div>
        </div>
        )}
    </>


  );
};

const MerchImage = ({ pic, setCurrentMerch, toggle, block }: any) => {
  //Image
  const refImage = useRef<HTMLDivElement | null>(null);
  const entryImage = useIntersectionObserver(refImage, {});
  const isVisibleImage = !!entryImage?.isIntersecting;
  const windowWidth = useWindowWidth();

  return (
    <Image
      key={pic.id}
      src={pic.image?.original}
      alt={pic.image?.title}
      containerClass={cn(styles.content__container, {
        [styles.content__container_active]: isVisibleImage,
      })}
      imageClass={styles.content__container_image}
      cursor={windowWidth && windowWidth > 1024 ? "custom" : "customMob"}
      ref={refImage}
      onClick={() => {
        setCurrentMerch(`${block?.title} ${pic?.key}`);
        toggle();
      }}
    />
  );
};

const MerchBlock: FC<MerchBlockProps> = ({ data }) => {
  const { isShowing, toggle } = useModal();
  const [currentMerch, setCurrentMerch] = useState<string>("");

  //Title
  const refTitle = useRef<HTMLDivElement | null>(null);
  const entryTitle = useIntersectionObserver(refTitle, {});
  const isVisibleTitle = !!entryTitle?.isIntersecting;

  //Description
  const refDescription = useRef<HTMLDivElement | null>(null);
  const entryDescription = useIntersectionObserver(refDescription, {});
  const isVisibleDescription = !!entryDescription?.isIntersecting;

  //Outro
  const refOutro = useRef<HTMLDivElement | null>(null);
  const entryOutro = useIntersectionObserver(refOutro, {});
  const isVisibleOutro = !!entryOutro?.isIntersecting;

  return (
    <>
      <div className={styles.merch}>
        {
          !!data.title && (
            <TitleBlock
              ref={refTitle}
              className={cn(styles.merch__title, {
                [styles.merch__title_active]: isVisibleTitle,
              })}
            >
              {data.title}
            </TitleBlock>
          )
        }
        
        <div
          className={cn(styles.merch__description, styles.description, {
            [styles.description_active]: isVisibleDescription,
          })}
          ref={refDescription}
        >
          {!!data.description?.title &&  (
            <h3 className={styles.description__title}>
              {data.description?.title}
            </h3>
          )}

          {!!data.description?.info &&  (
             <p className={styles.description__info}>{data.description.info}</p>
          )}
        </div>

        <div className={cn(styles.merch__shop, styles.shop)}>
          {!!data.shop.length && data.shop.map((block: any) => {
            const tovar = !!block?.tovar && Object.values(block.tovar) as any;

            return (
              <div id={block.key} key={block.id} className={styles.merch__shopBlock}>
                <MerchShop
                  block={block}
                  tovar={tovar}
                  setCurrentMerch={setCurrentMerch}
                  toggle={toggle}
                />
              </div>
            );
          })}
        </div>

        {!!data?.outro && (
          <h4
            className={cn(styles.merch__outro, {
              [styles.merch__outro_active]: isVisibleOutro,
            })}
            ref={refOutro}
          >
            {data.outro}
          </h4>
        )}
        
      </div>
      <CSSTransition
        in={isShowing}
        timeout={1000}
        classNames={{
          enterActive: styles.modal_enter_active,
          enterDone: styles.modal_enter_done,
          exitActive: styles.modal_exit_active,
          exit: styles.modal_exit,
        }}
        mountOnEnter
        unmountOnExit
      >
        <FormModal
          hide={toggle}
          formType={"black"}
          formName={"notify"}
          formTitle={"Заказ мерча"}
          formExtraInfo={currentMerch}
        />
      </CSSTransition>
    </>
  );
};

export { MerchBlock };
