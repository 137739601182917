const PATTERN = /\D/g;

export const getInputNumbersValue = (value: string) => {
  return value.replace(PATTERN, '');
};

export const handlePhoneInput = (evt: React.ChangeEvent<HTMLInputElement>) => {
  const input = evt.target;
  let inputNumbersValue = getInputNumbersValue(input.value);
  let formattedInputValue = '';
  const selectionStart = input.selectionStart;
  if (!inputNumbersValue) {
    return (input.value = '');
  }
  if (input.value.length !== selectionStart) {
    return;
  }
  if (['7', '8', '9'].indexOf(inputNumbersValue[0]) > -1) {
    if (inputNumbersValue[0] === '9') {
      inputNumbersValue = '7' + inputNumbersValue;
    }
    const firstSymbols = inputNumbersValue[0] === '8' ? '8' : '+7';
    formattedInputValue = firstSymbols + ' ';

    if (inputNumbersValue.length > 1) {
      formattedInputValue += '(' + inputNumbersValue.substring(1, 4);
    }
    if (inputNumbersValue.length >= 5) {
      formattedInputValue += ') ' + inputNumbersValue.substring(4, 7);
    }
    if (inputNumbersValue.length >= 8) {
      formattedInputValue += ' ' + inputNumbersValue.substring(7, 9);
    }
    if (inputNumbersValue.length >= 10) {
      formattedInputValue += ' ' + inputNumbersValue.substring(9, 11);
    }
  }
  input.value = formattedInputValue;
};

export const formattedPhoneInputValue = (
  evt: React.KeyboardEvent<HTMLInputElement>
) => {
  const input = evt.target as HTMLInputElement;

  if (
    evt.key === 'Backspace' &&
    getInputNumbersValue(input.value).length === 1
  ) {
    input.value = ' ';
  }

  return input;
};

export const handlePhonePaste = (
  event: React.ClipboardEvent<HTMLInputElement>
) => {
  const pasted = event.clipboardData ?? window['clipboardData' as any];
  const input = event.target as HTMLInputElement;
  const inputNumbersValue = getInputNumbersValue(input.value);
  if (pasted) {
    const pastedText = pasted.getData('Text');
    if (PATTERN.test(pastedText)) {
      input.value = inputNumbersValue;
    }
  }
};

export const normalizePhoneNumber = (telephoneNumber: string): string => {
  let formattedInputValue = '';
  const inputNumbersValue = telephoneNumber.replace(PATTERN, '');
  const firstSymbols = inputNumbersValue[0] === '8' ? '8' : '+7';
  formattedInputValue = firstSymbols;
  if (firstSymbols === '8') {
    formattedInputValue = inputNumbersValue;
  }
  if (firstSymbols === '+7') {
    formattedInputValue = '+' + inputNumbersValue;
  }

  return formattedInputValue;
};
