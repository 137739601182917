import { Layout } from '../../components/Layout';

import { PageContent } from '../../components/PageContent';
import { TitleBlock } from '../../components/PageBlocks/TitleBlock';
import { JobsBlock } from 'components/PageBlocks/JobsBlock';

import styles from './index.module.scss';

import { RootState } from 'store/store';
import { useSelector } from 'react-redux';

const CareerPage = () => {
	const karier = useSelector((state: RootState) => state.data.data.karier);

	return (
		<Layout className={styles.page}>
			<PageContent>
				<TitleBlock className={styles.page__title} type='h1'>
					{karier.name}
				</TitleBlock>
				<JobsBlock data={Object.values(karier.images)[0]} />
			</PageContent>
		</Layout>
	);
};

export { CareerPage };
