import { FC, useEffect } from 'react';

import styles from './index.module.scss';

import cn from 'classnames';

import { useField } from 'formik';

interface InputProps {
  name: string;
  type: string;
  readOnly?: any;
  placeholder: string;
  className?: string;
  handleInput?: (
    evt: React.ChangeEvent<HTMLInputElement>
  ) => '' | undefined | string;
  formattedInputValue?: (
    evt: React.KeyboardEvent<HTMLInputElement>
  ) => HTMLInputElement;
  handlePaste?: (event: React.ClipboardEvent<HTMLInputElement>) => void;
  maxLength?: number;
}

const Input: FC<InputProps> = ({
  type,
  className,
  placeholder,
  name,
  readOnly,
  handleInput,
  formattedInputValue,
  handlePaste,
  maxLength,
}) => {
  const [field, meta] = useField(name);

  return (
    <label className={cn(styles.input, className)}>
      <input
        className={cn(styles.input__field, {
          [styles.input__field_disabled]: readOnly,
        })}
        type={type}
        placeholder={placeholder}
        readOnly={readOnly}
        maxLength={maxLength}
        onInput={handleInput}
        onKeyDown={formattedInputValue}
        onPaste={handlePaste}
        {...field}
        autoComplete={'off'}
      />
      {meta.touched && meta.error ? (
        <div className={styles.input__field_error}>{meta.error}</div>
      ) : null}
    </label>
  );
};

export { Input };
