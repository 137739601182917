import { useEffect, useState } from 'react';

export const useOffsetScroll = (
	scrollElement: HTMLDivElement | null,
	offset: number,
	enable: boolean
) => {
	const [newOffset, setOffset] = useState<number>(0);

	useEffect(() => {
		if (!scrollElement) return;

		const handleScroll = () => {
			const allWidth = scrollElement.scrollWidth;
			const currentScroll = scrollElement.scrollLeft;
			const currentWidth = scrollElement.clientWidth;

			if (!allWidth || !currentScroll || !currentWidth) return;

			if (enable && currentScroll + currentWidth > allWidth - offset) {
				const newOffset = currentScroll + currentWidth - allWidth + offset;

				setOffset(Number(newOffset.toFixed()) || 0);
			} else {
				setOffset(0);
			}
		};

		const handleResize = () => {
			if (!enable) setOffset(0);
		};

		window.addEventListener('resize', handleResize);
		scrollElement.addEventListener('scroll', handleScroll);

		return () => {
			scrollElement.removeEventListener('scroll', handleScroll);
			window.removeEventListener('resize', handleResize);
		};
	}, [scrollElement, offset, enable]);

	return newOffset;
};
