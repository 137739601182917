import ReactDom from 'react-dom';
import styles from './index.module.scss';

import { ReactComponent as Close } from '../../assets/images/icon_burger_light_close.svg';
import logo from '../../assets/images/logo_vine&cheese_light.svg';

import { Image } from '../../components/Image';

import cn from 'classnames';

import { BookingForm } from 'layouts/Forms/BookingForm';
import { JobsForm } from 'layouts/Forms/JobsForm';
import { NotificationForm } from 'layouts/Forms/NotificationForm';
import { LogoVine } from 'assets/icons';

interface FormModalProps {
  hide?: any;
  extraHide?: any;
  modalType?: 'lavka' | 'magaz';
  formType: 'red' | 'black' | 'cold' | undefined;
  formName: 'job' | 'notify' | 'booking' | string;
  formTitle?: string;
  formExtraInfo?: string;
}

const FormModal = ({
  hide,
  extraHide,
  modalType,
  formType,
  formName,
  formTitle,
  formExtraInfo,
}: FormModalProps) => {
  return ReactDom.createPortal(
    <>
      <div
        className={cn(styles.modal, {
          [styles.modal_red]: formType === 'red',
          [styles.modal_black]: formType === 'black',
          [styles.modal_cold]: formType === 'cold',
        })}
        aria-modal
        aria-hidden
        tabIndex={-1}
        role='dialog'
      >
        <div className={styles.modal__wrapper}>
          <header className={styles.modal__header}>
            {formType === "cold" 
              ? <LogoVine bg={styles.vineBg} text={styles.vineText} />
              : <Image
                src={logo}
                alt={'Лого'}
                containerClass={styles.modal__header_logo}
              />}
            
            <button
              className={styles.modal__header_button}
              onClick={() => {
                if (modalType) {
                  extraHide();
                }
                hide();
              }}
            >
              <Close />
            </button>
          </header>
          {formName === 'job' && (
            <JobsForm
              className={styles.modal__form}
              formType={formType}
              hide={hide}
              formTitle={formTitle ? formTitle : ''}
            />
          )}
          {formName === 'notify' && (
            <NotificationForm
              className={styles.modal__form}
              formType={formType}
              hide={hide}
              extraHide={extraHide}
              modalType={modalType}
              formTitle={formTitle ? formTitle : ''}
              formExtraInfo={formExtraInfo ? formExtraInfo : ''}
            />
          )}
          {formName === 'booking' && (
            <BookingForm
              className={styles.modal__form}
              formType={formType}
              hide={hide}
            />
          )}
        </div>
      </div>
    </>,
    document.getElementById('root-modal') as HTMLElement
  );
};

export { FormModal };
