import { createSlice } from '@reduxjs/toolkit';
import Routes from 'constants/Routes';
import { MainNavProps } from 'types/types';
import { RootState } from './store';

const mainSlice = createSlice({
	name: 'main',
	initialState: {
		vine: [
			{ name: 'Ресторан', path: Routes.restaurant },
			{ name: 'Винный магазин', path: Routes.vine_shop },
			{ name: 'Эспрессо Бар', path: Routes.espresso_bar },
			{ name: 'Гастрономическая лавка', path: Routes.gastronomic_shop }
		],
		batya: [
			{ name: 'Мерч', path: Routes.merch },
			{ name: 'Кафе', path: Routes.cafe }
		],
		isMenu: false,
		isRouteFromMenu: {
			vine: false,
			batya: false
		},
		routeTo: '',
		scrollTo: '',
		fullScreenSLider: '',
		isHoverSide: 'cafe'
	} as MainNavProps,
	reducers: {
		setIsMenu: (state: MainNavProps) => {
			state.isMenu = true;
		},
		defaultIsMenu: (state: MainNavProps) => {
			state.isMenu = false;
		},
		setIsRouteFromMenuVine: (state: MainNavProps) => {
			state.isRouteFromMenu = {
				vine: true,
				batya: false
			};
		},
		setIsRouteFromMenuBatya: (state: MainNavProps) => {
			state.isRouteFromMenu = {
				vine: false,
				batya: true
			};
		},
		defaultIsRouteFromMenu: (state: MainNavProps) => {
			state.isRouteFromMenu = {
				vine: false,
				batya: false
			};
		},
		setRouteTo: (state: MainNavProps, { payload }) => {
			state.routeTo = payload;
		},
		defaultRouteTo: (state: MainNavProps) => {
			state.routeTo = '';
		},
		setScrollTo: (state: MainNavProps, { payload }) => {
			state.scrollTo = payload;
		},
		defaultScrollTo: (state: MainNavProps) => {
			state.scrollTo = '';
		},
		setfullScreenSLider: (state: MainNavProps, { payload }) => {
			state.fullScreenSLider = payload;
		},
		defaultfullScreenSLider: (state: MainNavProps) => {
			state.fullScreenSLider = '';
		},
		setHoverSide: (state: MainNavProps, { payload }) => {
			state.isHoverSide = payload;
		},
		defaultHoverSide: (state: MainNavProps) => {
			state.isHoverSide = 'cafe';
		}
	}
});

export const mainSelector = (state: RootState) => state.main;
export const {
	setIsMenu,
	defaultIsMenu,
	setIsRouteFromMenuVine,
	setIsRouteFromMenuBatya,
	defaultIsRouteFromMenu,
	setRouteTo,
	defaultRouteTo,
	setScrollTo,
	defaultScrollTo,
	setfullScreenSLider,
	defaultfullScreenSLider,
	setHoverSide,
	defaultHoverSide
} = mainSlice.actions;

export default mainSlice.reducer;
