import { createSlice } from '@reduxjs/toolkit';
import { storageInteraction } from 'helpers/storageInteraction';
import { RootState } from './store';

interface IAgeType {
    isAdult: boolean;
}

const ageSlice = createSlice({
  name: 'age',
  initialState: storageInteraction("isAdult") || {
    isAdult: false,
  } as IAgeType,
  reducers: {
    setIsAdult: (state: IAgeType) => {
        state.isAdult = true;
    }
  }
});

export const ageSelector = (state: RootState) => state.age;
export const { setIsAdult } = ageSlice.actions;

export default ageSlice.reducer;
