import { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import Slider from 'react-slick';

import { Layout } from '../../components/Layout';

import styles from './index.module.scss';

import { RootState } from 'store/store';
import { useDispatch, useSelector } from 'react-redux';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './slick.scss';
import Dot from './components/Dot';
import { IPosition } from './types';
import CafeBlock from './components/CafeBlock';
import BatyaBlock from './components/BatyaBlock';
import VideoBlock from './components/VideoBlock';
import {
  defaultfullScreenSLider,
  defaultHoverSide,
  mainSelector,
  setfullScreenSLider,
  setHoverSide
} from 'store/mainSlice';
import ReactPlayer from 'react-player';

const MainPage = () => {
  const dispatch = useDispatch();
  const { data } = useSelector((state: RootState) => state.data);
  const { isHoverSide } = useSelector(mainSelector);
  const caferef = useRef<Slider>(null);
  const batyaref = useRef<Slider>(null);
  const dotref = useRef<HTMLInputElement>(null);
  const videocaferef = useRef<ReactPlayer>(null);
  const videobatyaref = useRef<ReactPlayer>(null);

  const [isHoverCafe, setIsHoverCafe] = useState<boolean>(true);
  const [isHoverBatya, setIsHoverBatya] = useState<boolean>(false);
  const [isfullScreenCafe, setIsFullScreenCafe] = useState<boolean>(false);
  const [isfullScreenBatya, setIsFullScreenBatya] = useState<boolean>(false);
  const [isHoverSliderCafe, setIsHoverSliderCafe] = useState<boolean>(false);
  const [isHoverSliderBatya, setIsHoverSliderBatya] = useState<boolean>(false);
  const [isDotVisible, setIsDotVisible] = useState<boolean>(false);
  const [position, setPosition] = useState<IPosition>({
    left: 0,
    top: 0
  });

  useEffect(() => {
    dispatch(defaultfullScreenSLider());
    dispatch(defaultHoverSide());
    setIsHoverCafe(true);
    setIsHoverBatya(false);
  }, []);

  const handleMouseMove = (e: any) => {
    if (dotref?.current) {
      const clientRect = dotref?.current?.getBoundingClientRect();
      setPosition({
        left: e.clientX,
        top: e.clientY - clientRect.top + 88
      });
    }
  };

  const handleMouseLeave = () => {
    setIsDotVisible(false);
  };

  const handleMouseEnter = () => {
    setIsDotVisible(true);
  };

  useEffect(() => {
    if (window.innerWidth > 1279) {
      if (!!caferef.current) {
        isHoverSliderCafe
          ? caferef.current.slickPlay()
          : caferef.current.slickPause();
      }
      if (!!batyaref.current) {
        isHoverSliderBatya
          ? batyaref.current.slickPlay()
          : batyaref.current.slickPause();
      }
    }
  }, [isHoverSliderCafe, isHoverSliderBatya]);

  const handleHoverSliderCafeArea = (val: boolean) => {
    setIsHoverSliderCafe(val);
  };

  const handleHoverSliderBatyaArea = (val: boolean) => {
    setIsHoverSliderBatya(val);
  };

  const handleHoverCafe = () => {
    if (window.innerWidth > 743) {
      setIsHoverCafe(true);
      setIsHoverBatya(false);
    }
  };
  const handleHoverBatya = () => {
    if (window.innerWidth > 743) {
      setIsHoverBatya(true);
      setIsHoverCafe(false);
    }
  };

  const handleAreaClickCafe = () => {
    if (window.innerWidth > 743) {
      if (!isfullScreenCafe) {
        dispatch(setfullScreenSLider('vine'));
        setIsFullScreenCafe(true);
      } else {
        dispatch(defaultfullScreenSLider());
        setIsFullScreenCafe(false);
      }
    } else {
      if (isHoverCafe) {
        isfullScreenCafe
          ? setIsFullScreenCafe(false)
          : setIsFullScreenCafe(true);
      } else {
        setIsHoverCafe(true);
        setIsHoverBatya(false);
      }
    }
  };

  const handleAreaClickBatya = () => {
    if (window.innerWidth > 743) {
      if (!isfullScreenBatya) {
        dispatch(setfullScreenSLider('batya'));
        setIsFullScreenBatya(true);
      } else {
        dispatch(defaultfullScreenSLider());
        setIsFullScreenBatya(false);
      }
    } else {
      if (isHoverBatya) {
        isfullScreenBatya
          ? setIsFullScreenBatya(false)
          : setIsFullScreenBatya(true);
      } else {
        setIsHoverBatya(true);
        setIsHoverCafe(false);
      }
    }
  };

  useEffect(() => {
    if (window.innerWidth < 744) {
      isHoverCafe && dispatch(setHoverSide('cafe'));
      isHoverBatya && dispatch(setHoverSide('batya'));
      isfullScreenCafe && dispatch(setfullScreenSLider('vine'));
      isfullScreenBatya && dispatch(setfullScreenSLider('batya'));
      !isfullScreenCafe &&
        !isfullScreenBatya &&
        dispatch(defaultfullScreenSLider());
    }
  }, [isHoverBatya, isfullScreenBatya, isHoverCafe, isfullScreenCafe]);

  return (
    <Layout>
      <main
        className={cn(styles.main, {
          [styles.main_batya]: isHoverSide === 'batya'
        })}
      >
        <section
          className={styles.main__section}
          ref={dotref}
          onMouseMove={handleMouseMove}
          onMouseLeave={handleMouseLeave}
          onMouseEnter={handleMouseEnter}
        >
          {!!data.config.video_file_main && (
            <VideoBlock
              classname={cn(styles.main__video, {
                [styles.main__video_show]: !isHoverBatya && !isfullScreenCafe,
                [styles.main__video_hide]: isfullScreenCafe || isfullScreenBatya
              })}
              url={data.config.video_file_main}
              title={data.config.static_text.static_text_2}
              innerRef={videocaferef}
            />
          )}

          {!!data.config.video_file_batya && (
            <VideoBlock
              classname={cn(styles.main__video, styles.main__video_batya, {
                [styles.main__video_show]: !isHoverCafe && !isfullScreenBatya,
                [styles.main__video_hideBatya]:
                  isfullScreenCafe || isfullScreenBatya
              })}
              url={data.config.video_file_batya}
              title={data.config.static_text.static_text_3}
              batya
              innerRef={videobatyaref}
            />
          )}

          <Dot
            className={styles.main__dot}
            isDotVisible={isDotVisible}
            isHoverSliderCafe={isHoverSliderCafe}
            isHoverSliderBatya={isHoverSliderBatya}
            isHoverCafe={isHoverCafe}
            isHoverBatya={isHoverBatya}
            isfullScreenBatya={isfullScreenBatya}
            isfullScreenCafe={isfullScreenCafe}
            position={position}
          />

          <CafeBlock
            isHoverCafe={isHoverCafe}
            isfullScreenBatya={isfullScreenBatya}
            isfullScreenCafe={isfullScreenCafe}
            handleHoverCafe={handleHoverCafe}
            innerRef={caferef}
            data={data.main_slider.images}
            cnBlock={cn(styles.main__sliderWrap, styles.main__sliderWrap_cafe, {
              [styles.main__sliderWrap_hover]: isHoverCafe,
              [styles.main__sliderWrap_hide]: isfullScreenBatya,
              [styles.main__sliderWrap_full]: isfullScreenCafe
            })}
            cnArea={cn(styles.main__hoverArea, {
              [styles.main__hoverArea_hide]:
                isfullScreenBatya || isfullScreenCafe
            })}
            cnFooter={styles.main__footer}
            cnStatic={styles.main__static}
            handleAreaEnter={() => handleHoverSliderCafeArea(true)}
            handleAreaLeave={() => handleHoverSliderCafeArea(false)}
            handleAreaClick={handleAreaClickCafe}
            handleMouseLeave={handleMouseLeave}
            handleMouseEnter={handleMouseEnter}
          />

          <BatyaBlock
            isHoverBatya={isHoverBatya}
            isfullScreenCafe={isfullScreenCafe}
            isfullScreenBatya={isfullScreenBatya}
            handleHoverBatya={handleHoverBatya}
            innerRef={batyaref}
            data={data.main_slider.images}
            cnBlock={cn(
              styles.main__sliderWrap,
              styles.main__sliderWrap_batya,
              {
                [styles.main__sliderWrap_hover]: isHoverBatya,
                [styles.main__sliderWrap_hide]: isfullScreenCafe,
                [styles.main__sliderWrap_full]: isfullScreenBatya
              }
            )}
            cnArea={cn(styles.main__hoverArea, {
              [styles.main__hoverArea_hide]:
                isfullScreenBatya || isfullScreenCafe
            })}
            cnFooter={styles.main__footer}
            cnStatic={styles.main__static}
            handleAreaEnter={() => handleHoverSliderBatyaArea(true)}
            handleAreaLeave={() => handleHoverSliderBatyaArea(false)}
            handleAreaClick={handleAreaClickBatya}
            handleMouseLeave={handleMouseLeave}
            handleMouseEnter={handleMouseEnter}
          />
        </section>
      </main>
    </Layout>
  );
};

export { MainPage };
