import { FC, useRef, useState } from 'react';

import styles from './index.module.scss';

import cn from 'classnames';
import useAutosizeTextArea from 'hooks/useAutoResizeTextAria';
import { useField } from 'formik';

interface TextareaProps {
  className?: string;
  name: string;
  placeholder: string;
}

const Textarea: FC<TextareaProps> = ({ className, placeholder, name }) => {
  const [value, setValue] = useState('');
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const labelRef = useRef<HTMLLabelElement>(null);
  const [field, meta] = useField(name);

  useAutosizeTextArea(textAreaRef.current, value);
  useAutosizeTextArea(labelRef.current, value);

  const handleChange = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    const val = evt.target?.value;
    setValue(val);
  };

  return (
    <label className={cn(styles.textarea, className)}>
      <textarea
        className={cn(styles.textarea__field)}
        placeholder={placeholder}
        onInput={handleChange}
        ref={textAreaRef}
        {...field}
      />
      {meta.touched && meta.error ? (
        <div className={styles.textarea__field_error}>{meta.error}</div>
      ) : null}
    </label>
  );
};

export { Textarea };
