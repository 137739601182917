import Routes from 'constants/Routes';

export const getNumberOfRoute = (route: string) => {
  switch (route) {
    case Routes.home:
      return '11';
    case Routes.restaurant:
      return '4';
    case Routes.espresso_bar:
      return '3';
    case Routes.vine_shop:
      return '5';
    case Routes.gastronomic_shop:
      return '6';
    case Routes.cafe:
      return '7';
    case Routes.merch:
      return '8';
    case Routes.career:
      return '9';
    case Routes.hackwork:
      return '10';
    case Routes.contacts:
      return '12';
    default:
      return '';
  }
};
