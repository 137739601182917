import { useEffect, useState } from 'react';

import { Layout } from '../../components/Layout';
import { PageFooter } from '../../components/PageFooter';
import { PageContent } from '../../components/PageContent';

import { TitleBlock } from '../../components/PageBlocks/TitleBlock';
import { NextPageBlock } from '../../components/PageBlocks/NextPageBlock';
import { IntroBlock } from './sections/IntroBlock';
import { MerchBlock } from './sections/MerchBlock';

import styles from './index.module.scss';

import Routes from '../../constants/Routes';
import { useWindowWidth } from 'hooks/useWindowWidth';

import { RootState } from 'store/store';
import { useSelector } from 'react-redux';
import { useOffsetScroll } from 'hooks/useOffsetScroll';
import { useHeaderInvertContext } from 'context/headerInvert';

const pageData = {
	footer: {
		link: ['Задать вопрос по мерчу'],
		formTitle: 'Задать вопрос'
	},
	next: {
		name: 'Мерч',
		link: `${Routes.restaurant}`
	}
};

const MerchPage = () => {
	const windowWidth = useWindowWidth();
	const [scrollElementNode, setScrollElementNode] =
		useState<HTMLDivElement | null>(null);

	const { setInvertProcentLogoRight } = useHeaderInvertContext();

	const offset = useOffsetScroll(
		scrollElementNode,
		201,
		!!windowWidth && windowWidth > 1279
	);

	useEffect(() => {
		setInvertProcentLogoRight((100 / 201) * offset);
	}, [offset]); //eslint-disable-line

	useEffect(() => {
		return () => {
			setInvertProcentLogoRight(0);
		};
	}, []); //eslint-disable-line

	const merch = useSelector((state: RootState) => state.data.data.merch);

	const merchData = {
		title: 'В наличии',
		description: {
			title: merch.description,
			info: merch.text_block1
		},
		shop: Object.values(merch.prodcat),
		outro: merch.text_block2
	};

	return (
		<Layout className={styles.page}>
			<PageContent
				getElement={setScrollElementNode}
				className={styles.page__wrapper}
			>
				<TitleBlock className={styles.page__title} type='h1'>
					{merch.name}
				</TitleBlock>
				<IntroBlock data={merch.images['group-image'][0]} />
				<MerchBlock data={merchData} />

				<NextPageBlock
					data={pageData.next}
					className={styles.page__next}
					type={'vine-cheese'}
					style={{
						transform: offset ? `translateX(${offset * -1}px)` : undefined
					}}
				/>
			</PageContent>

			{windowWidth && windowWidth > 1279 && (
				<PageFooter
					data={pageData.footer}
					className={styles.page__footer}
					formType={'black'}
					style={{
						transform: offset ? `translateX(${offset * -1}px)` : undefined
					}}
				/>
			)}
		</Layout>
	);
};

export { MerchPage };
