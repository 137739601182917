export const filterProductsData = (value: any, type: 'all' | 'static') => {
  return Object.values(value).filter((el: any) => {
    if (type === 'all') {
      return el.key !== 'prodcat-static';
    }
    if (type === 'static') {
      return el.key === 'prodcat-static';
    }
  });
};
