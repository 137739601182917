import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { BACKEND_URL } from 'constants/global';
import { AppDispatch, RootState } from './store';
import db from '../db.json';

interface allDataSliceProps {
	data: any;
	isLoading: boolean;
	isLoaded: boolean;
	error: any;
}

const allDataSlice = createSlice({
	name: 'data',
	initialState: {
		data: {},
		isLoading: true,
		isLoaded: false,
		error: null
	} as allDataSliceProps,
	reducers: {
		getAllData: (state: allDataSliceProps) => {
			state.isLoading = true;
			state.isLoaded = false;
		},
		getAllDataSuccess: (state: allDataSliceProps, { payload }) => {
			state.data = payload;
			state.isLoading = false;
			state.isLoaded = true;
		},
		getAllDataFailure: (state: allDataSliceProps, { payload }) => {
			state.isLoading = false;
			state.isLoaded = true;
			state.error = payload;
		}
	}
});

export const { getAllData, getAllDataSuccess, getAllDataFailure } =
	allDataSlice.actions;

export const getData = () => {
	return async (dispatch: AppDispatch, getState: () => RootState) => {
		/*	dispatch(getAllData());
		dispatch(getAllDataSuccess(db));*/
		dispatch(getAllData());
		axios
			.get(`${BACKEND_URL}/data/`)
			.then(response => dispatch(getAllDataSuccess(response.data)))
			.catch(error => dispatch(getAllDataFailure(error)));
	};
};

export const dataSelector = (state: allDataSliceProps) => state.data;
export default allDataSlice.reducer;
