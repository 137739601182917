import { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import cn from 'classnames';

import styles from './index.module.scss';

interface IModal {
	children?: JSX.Element | JSX.Element[] | null;
	container?: HTMLBodyElement | HTMLElement;
	visible: boolean;
}

const IosModal = ({
	children = null,
	container = document.body,
	visible
}: IModal) => (
	<>
		{createPortal(
			<div
				className={cn(styles.iosPopup, {
					[styles.iosPopup_visible]: visible
				})}
			>
				{children}
			</div>,
			container
		)}
	</>
);

export default IosModal;
