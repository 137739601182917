export const checkDayInMonth = (value: string) => {
  if (value.length === 5) {
    const array = value.split('.');
    const days = Number(array[0]);
    const month = Number(array[1]);
    const currentDays = new Date(2016, month, 0).getDate();
    if (currentDays >= days) {
      return false;
    } else {
      return true;
    }
  }
};
