import { useCallback, useEffect, useRef, useState } from 'react';

import styles from './index.module.scss';

import cn from 'classnames';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperType, Navigation, Pagination } from 'swiper';

import 'swiper/css';
import SwiperCore from 'swiper';

import { Image } from '../../Image';

import { Arrow, ArrowPrev } from 'assets/icons';
import useIntersectionObserver from 'hooks/useIntersectionObserver';

SwiperCore.use([Navigation, Pagination]);

interface SliderBlockProps {
  data: {
    description: string;
    group_name: string;
    id: number;
    id_model: number;
    is_main: number;
    key: string;
    marker: string;
    order_image: number;
    original: string;
    resize1: string;
    resize2: string;
    resize3: string;
    text_block1: boolean;
    text_block2: boolean;
    title: string;
    type: number;
  }[];
  className?: string;
  convertColors?: boolean;
}

const SliderBlock = ({ data, className, convertColors }: SliderBlockProps) => {
  //Slider Logic//
  const ref = useRef<SwiperType>();
  const paginationRef = useRef(null);
  const [isArrowPosition, setIsArrowPosition] = useState<string | null>(null);

  //Slider Params//
  const params = {
    spaceBetween: 20,
    slidesPerView: 1,
    allowTouchMove: true,
    modules: [Navigation, Pagination],
    breakpoints: {
      1280: {
        allowTouchMove: false,
      }
    },
    ref,
    onBeforeInit: (swiper: any) => {
      ref.current = swiper;
      swiper.params.pagination.el = paginationRef.current;
    }
  };

  //Animations//
  const refSlider = useRef<HTMLDivElement | any>(null);
  const entry = useIntersectionObserver(refSlider, {});
  const isVisible = !!entry?.isIntersecting;

  //Button Logic//
  const [buttonPos, setButtonPos] = useState({ x: 0, y: 0 });

  const handlerMoveMouse = useCallback((evt: any) => {
    const rect = refSlider.current.getBoundingClientRect();
    setButtonPos({ x: evt.x - rect.x, y: evt.y - rect.y });
  }, []);

  useEffect(() => {
    const _ref = refSlider.current;
    _ref.addEventListener('mousemove', handlerMoveMouse);
    return () => {
      _ref.removeEventListener('mousemove', handlerMoveMouse);
    };
  }, [handlerMoveMouse]);


  return (
    <div
      className={cn(styles.slider, className, {
        [styles.slider_active]: isVisible,
        [styles.slider_active_one]: isVisible && data.length === 1,
      })}
      ref={refSlider}
      onMouseLeave={() => setIsArrowPosition(null)}
    >
      {!!data && data.length === 1 && (
        <Image
          src={data[0].original}
          alt={data[0].original}
          containerClass={cn(styles.slider__slide, styles.slider__slide_1)}
          imageClass={styles.slider__slide_img}
        />
      )}
      {!!data && data.length > 1 && (
        <>
          <div
            className={styles.slider__leftSide}
            onClick={() => {
              ref.current?.slidePrev();
            }}
            onMouseEnter={() => {
              setIsArrowPosition('left');
            }}
          >
            {
              window.innerWidth < 1280 &&
              !convertColors &&
              <ArrowPrev cnBg={styles.slider__buttonBg} cnEl={styles.slider__buttonEl} />
            }
            {
              window.innerWidth < 1280 &&
              convertColors &&
              <ArrowPrev />
            }
          </div>
          <div
            className={styles.slider__rightSide}
            onClick={() => {
              ref.current?.slideNext();
            }}
            onMouseEnter={() => {
              setIsArrowPosition('right');
            }}
          >
            {
              window.innerWidth < 1280 &&
              !convertColors &&
              <Arrow cnBg={styles.slider__buttonBg} cnEl={styles.slider__buttonEl} />
            }
            {
              window.innerWidth < 1280 &&
              convertColors &&
              <Arrow />
            }
          </div>
          <Swiper
            {...params}
            className={styles.slider__container}
            loop={true}
            pagination={{
              type: 'custom',
              el: paginationRef.current,
              renderCustom: (swiper, current, total) => {
                return `<div class='${styles.slider__pagination}'>
                      <span>${current}/${total}</span>
                    </div>`;
              },
            }}
          >
            {!!data.length &&
              data.map((slide: any, index: number) => (
                <SwiperSlide className={styles.slider__slide} key={index}>
                  <Image
                    src={slide.original}
                    alt={slide.title}
                    containerClass={styles.slider__slide}
                    imageClass={styles.slider__slide_img}
                  />
                </SwiperSlide>
              ))}
            <div
              className={styles.simpleSlider__amount}
              ref={paginationRef}
            ></div>
          </Swiper>
          <div
            className={cn(styles.slider__button, {
              [styles.slider__button_hide]: isArrowPosition === null,
            })}
            style={{ left: buttonPos.x, top: buttonPos.y }}
          >
            {
              !convertColors &&
              isArrowPosition === 'right' &&
              <Arrow cnBg={styles.slider__buttonBg} cnEl={styles.slider__buttonEl} />
            }
            {
              !convertColors &&
              isArrowPosition === 'left' &&
              <ArrowPrev cnBg={styles.slider__buttonBg} cnEl={styles.slider__buttonEl} />
            }
            {
              convertColors &&
              isArrowPosition === 'right' &&
              <Arrow />
            }
            {
              convertColors &&
              isArrowPosition === 'left' &&
              <ArrowPrev />
            }
          </div>
        </>
      )}
    </div>
  );
};

export { SliderBlock };
