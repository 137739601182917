import { Layout } from '../../components/Layout';
import { MapBlock } from 'components/MapBlock';

import pin29 from "../../assets/images/pin29.svg"
import pinWine from "../../assets/images/pinWine.svg"

import styles from './index.module.scss';

const pageData = {
  default: {
    coord: [44.601183, 34.35208],
    zoom: 16.8,
  },
  marks: [{
    coord: [44.601183, 34.35208],
    pin: pin29
  },
  {
    coord: [44.600349, 34.351028],
    pin: pinWine
  }],
};

const ContactsPage = () => {
  return (
    <Layout className={styles.page}>
      <MapBlock className={styles.page__map} data={pageData} />
    </Layout>
  );
};

export { ContactsPage };
