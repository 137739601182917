import styles from "./index.module.scss";

import cn from "classnames";

import { CSSTransition } from "react-transition-group";
import { FormModal } from "layouts/FormModal";
import { useModal } from "hooks/useModal";
import { CSSProperties, useState } from "react";

interface PageFooterProps {
  data: {
    info?: string;
    link?: string[];
    formTitle: string;
  };
  className?: string;
  style?: CSSProperties;
  formType?: "red" | "black" | "cold" | undefined;
}

const PageFooter = ({ className, style, data, formType }: PageFooterProps) => {
  const { isShowing, toggle } = useModal();
  const [formName, setFormName] = useState<string>("");

  return (
    <>
      <footer className={cn(styles.footer, className)} style={style}>
        <div className={styles.footer__wrapper}>
          {data.info && <div className={styles.footer__info}>
            <p>
              {data.info}
            </p>
          </div>}
          {data.link?.length === 2 && (
            <div className={styles.footer__links}>
              <span
                className={cn(styles.footer__link, {
                  [styles.footer__link_red]: formType === "red",
                  [styles.footer__link_black]: formType === "black",
                })}
                onClick={() => {
                  setFormName("booking");
                  toggle();
                }}
              >
                {data.link[0]}
              </span>
              <span
                className={styles.footer__link}
                onClick={() => {
                  setFormName("notify");
                  toggle();
                }}
              >
                {data.link[1]}
              </span>
            </div>
          )}
          {data.link?.length === 1 && (
            <div className={styles.footer__links}>
              <span
                className={styles.footer__link}
                onClick={() => {
                  setFormName("notify");
                  toggle();
                }}
              >
                {data.link[0]}
              </span>
            </div>
          )}
        </div>
      </footer>
      <CSSTransition
        in={isShowing}
        timeout={1000}
        classNames={{
          enterActive: styles.modal_enter_active,
          enterDone: styles.modal_enter_done,
          exitActive: styles.modal_exit_active,
          exit: styles.modal_exit,
        }}
        mountOnEnter
        unmountOnExit
      >
        <FormModal
          hide={toggle}
          formType={formType}
          formName={formName}
          formTitle={data.formTitle}
        />
      </CSSTransition>
    </>
  );
};

export { PageFooter };
