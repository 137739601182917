import styles from './index.module.scss';

import cn from 'classnames';

import { Button } from 'components/UI/Button';
import { Input } from 'components/UI/Input';
import { Textarea } from 'components/UI/Textarea';

import { SocialsInput } from 'components/FormFields/SocialsInput';

import { Formik, Form, FormikErrors } from 'formik';
import {
  formattedPhoneInputValue,
  handlePhoneInput,
  handlePhonePaste,
  normalizePhoneNumber,
} from 'utils/inputNumberMask';
import { useState } from 'react';
import { useFormMutation } from 'store/formAPISlice';
import { getNumberOfRoute } from 'utils/getNumberOfRoute';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';

interface FormProps {
  className?: string;
  formType: 'red' | 'black' | 'cold' | undefined;
  formTitle: string;
  formExtraInfo: string;
  hide?: any;
  extraHide?: any;
  modalType?: 'lavka' | 'magaz';
}

interface FormValues {
  type: string;
  object_id: string;
  fio: string;
  phone: string;
  social: string;
  text: string;
}

const NotificationForm = ({
  className,
  formType,
  hide,
  extraHide,
  modalType,
  formExtraInfo,
  formTitle,
}: FormProps) => {
  const data = useSelector((state: RootState) => state.data.data);
  const [form, { isSuccess, isError }] = useFormMutation();

  const location = useLocation();

  const [contact, setContact] = useState<string>('telegram');
  const [isSend, setIsSend] = useState<boolean>(false);

  const validate = (values: FormValues) => {
    let errors: FormikErrors<FormValues> = {};
    const nameRegex = /^[a-zA-Zа-яёА-ЯЁ\s]+$/u;
    const phoneRegex =
      /^(\+7|7|8)?[\s]?\(?[489][0-9]{2}\)?[\s]?[0-9]{3}[\s]?[0-9]{2}[\s]?[0-9]{2}/;
    const telegramRegex = /.*\B@(?=\w{5,32}\b)[a-zA-Z0-9]+(?:_[a-zA-Z0-9]+)*.*/;

    if (!values.fio) {
      errors.fio = 'Обязательное поле';
    } else if (!nameRegex.test(values.fio)) {
      errors.fio = 'Введите корректное имя';
    }

    if (!values.phone) {
      errors.phone = 'Обязательное поле';
    } else if (!phoneRegex.test(values.phone)) {
      errors.phone = 'Введите телефон в верном формате';
    }

    if (contact === 'telegram') {
      if (!values.social) {
        errors.social = 'Обязательное поле';
      } else if (!telegramRegex.test(values.social)) {
        errors.social = 'Введите id в формате @username';
      }
    }

    return errors;
  };
  return (
    <>
      <Formik
        initialValues={{
          type: '1',
          object_id: '',
          fio: '',
          phone: '',
          social: '',
          text: '',
        }}
        validateOnMount
        validateOnChange
        validate={validate}
        onSubmit={async (values, { resetForm }) => {
          if (!isSend) {
            setIsSend(true);

            const formdata = new FormData();
            formdata.append('type', values.type);
            formdata.append('object_id', getNumberOfRoute(location.pathname));
            formdata.append('fio', values.fio);
            formdata.append('phone', normalizePhoneNumber(values.phone));
            formdata.append('social', values.social);
            formdata.append(
              'text',
              `${values.text}${formExtraInfo ? `\n${formExtraInfo}` : ''}`
            );
            try {
              await form(formdata);
              setTimeout(() => {
                hide();
                if (modalType) {
                  extraHide();
                }
                resetForm();
              }, 1000);
            } catch (error: any) {
              setTimeout(() => {
                hide();
                if (modalType) {
                  extraHide();
                }
                resetForm();
              }, 1000);
            }
          }

        }}
      >
        <Form className={cn(styles.form, className)}>
          <h2 className={styles.form__title}>
            <span>{formTitle}</span>
          </h2>
          <span className={styles.form__point}>*- Обязательное поле</span>
          <div className={styles.form__block}>
            <div className={styles.form__group}>
              <Input
                name='fio'
                type='text'
                placeholder='Имя*'
                className={styles.form__group_name}
              />
              <Input
                name='phone'
                type='tel'
                placeholder={'+7*'}
                handleInput={handlePhoneInput}
                formattedInputValue={formattedPhoneInputValue}
                handlePaste={handlePhonePaste}
                maxLength={18}
                className={styles.form__group_number}
              />
              <SocialsInput
                name='social'
                type='text'
                placeholder={'id telegram'}
                className={styles.form__group_socials}
                contact={contact}
                setContact={setContact}
                formType={formType}
              />
            </div>
            <div className={styles.form__group}>
              <Textarea
                name='text'
                placeholder={'Комментарий'}
                className={styles.form__group_text}
              />
            </div>
          </div>
          <footer className={cn(styles.form__footer, styles.footer)}>
            <p className={styles.footer__text}>
              нажимая на кнопку “отправить”, вы соглашаетесь на{' '}
              <a href={!!data.config.file_policy_1 ? data.config.file_policy_1 : "#"} target={"_blank"} className={styles.footer__text_link}>
                обработку персональных данных
              </a>
            </p>
            <Button className={styles.footer__button} type={'submit'} disabled={isSend}>
              Отправить
            </Button>
          </footer>
        </Form>
      </Formik>
      {isSuccess && (
        <h3
          className={cn(styles.form__success, {
            [styles.form__success_red]: formType === 'red',
            [styles.form__success_black]: formType === 'black',
            [styles.form__success_cold]: formType === 'cold',
          })}
        >
          Отправлено
        </h3>
      )}
      {isError && (
        <h3
          className={cn(styles.form__success, {
            [styles.form__success_red]: formType === 'red',
            [styles.form__success_black]: formType === 'black',
            [styles.form__success_cold]: formType === 'cold',
          })}
        >
          Произошла ошибка
        </h3>
      )}
    </>
  );
};

export { NotificationForm };
