// @ts-nocheck

import styles from './index.module.scss';

import { Section } from './sections/Section';
import { Footer } from './sections/Footer';
import Routes from 'constants/Routes';

import { RootState } from 'store/store';
import { useSelector } from 'react-redux';

/*import rest from "../../assets/images/bg_hover_rest.png";
import esp from "../../assets/images/bg_hover_esp.png";
import vine from "../../assets/images/bg_hover_vine.png";
import lavka from "../../assets/images/bg_hover_lavka.png";
import cafe from "../../assets/images/bg_hover_cafe.png";
import merch from "../../assets/images/bg_hover_merch.png";*/

const Menu = () => {
	const menu = useSelector((state: RootState) => state.data.data);

	const navData = {
		vine_cheese: {
			place: [
				{
					title: { name: menu.rest.name, href: `${Routes.restaurant}` },
					links: menu.config.rest,
					pdf: true
				},
				{
					title: { name: menu.esp.name, href: `${Routes.espresso_bar}` },
					links: menu.config.esp,
					pdf: true
				},
				{
					title: { name: menu.magaz.name, href: `${Routes.vine_shop}` }
				},
				{
					title: {
						name: menu.lavka.name,
						href: `${Routes.gastronomic_shop}`
					}
				}
			],
			socials: [
				{ name: 'Instagram', link: menu.social.magaz.instagram },
				{ name: 'Telegram', link: menu.social.magaz.telegram },
				{ name: 'Vk', link: menu.social.magaz.vkontakte }
			],
			contact: {
				name: menu.social.magaz.phone
			},
			images: [
				menu?.rest?.images?.navigationStatic[0]?.original,
				menu?.esp?.images?.navigationStatic[0]?.original,
				menu?.magaz?.images?.navigationStatic[0]?.original,
				menu?.lavka?.images?.navigationStatic[0]?.original
			]
		},
		batya29: {
			place: [
				{
					title: { name: menu.cafe.name, href: `${Routes.cafe}` },
					links: menu.config.cafe,
					pdf: true
				},
				{
					title: { name: menu.merch.name, href: `${Routes.merch}` },
					links: Object.values(menu.merch.prodcat).map(prodcat => ({
						label: prodcat.title,
						id: prodcat.key
					}))
				}
			],
			socials: [
				{ name: 'Instagram', link: menu.social.b29.instagram },
				{ name: 'Telegram', link: menu.social.b29.telegram },
				{ name: 'Vk', link: menu.social.b29.vkontakte }
			],
			contact: {
				name: menu.social.b29.phone
			},
			images: [
				menu?.cafe?.images?.navigationStatic[0]?.original,
				menu?.merch?.images?.navigationStatic[0]?.original
			]
		},
		footer: [
			{ name: menu.karier.name, href: `${Routes.career}` },
			{ name: 'Контакты', href: `${Routes.contacts}` },
			{ name: menu.haltura.name, href: `${Routes.hackwork}` }
		]
	};

	return (
		<div className={styles.menu}>
			<div
				className={styles.menu__wrap}
				ref={wrap => {
					wrap && wrap.scrollTo(0, 0);
				}}
			>
				<div className={styles.menu__sections}>
					<Section
						data={navData.vine_cheese}
						className={'vine_cheese'}
						type={'vine'}
					/>
					<Section
						data={navData.batya29}
						className={'batya29'}
						type={'batya'}
					/>
				</div>
				<Footer data={navData.footer} />
			</div>
		</div>
	);
};

export { Menu };
