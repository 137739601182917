import Routes from "constants/Routes";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";


interface IMetaTags {
    title: string;
    metaTitle: string;
    metaDescription: string;
}

export const useMetaTags = () => {
    const location = useLocation();
    const [metaTags, setMetaTags] = useState<IMetaTags>({
        title: "",
        metaTitle: "",
        metaDescription: ""
    })

    useEffect(() => {
        if (location.pathname === Routes.restaurant) {
            setMetaTags({
                title: "Ресторан «Вино&Сыр»",
                metaTitle: "Ресторан «Вино&Сыр». Будь там, где вкусно!",
                metaDescription: "Вкусные блюда, изысканный интерьер, свежие продукты и вежливый персонал! Ждём вас! Забронировать стол или оформить доставку «Вино&Сыр»: +7 (978) 069-01-01"
            });
        } else if (location.pathname === Routes.espresso_bar) {
            setMetaTags({
                title: "Эспрессо-бар",
                metaTitle: "Эспрессо-бар – здесь готовят вкусный кофе!",
                metaDescription: "Натуральные ингредиенты. Профессиональные бариста. Авторские десерты. Чашечка ароматного кофе ждет тебя!"
            });
        } else if (location.pathname === Routes.vine_shop) {
            setMetaTags({
                title: "Винный бутик «Вино&Сыр»",
                metaTitle: "Винный бутик «Вино&Сыр» – профессиональный винный магазин в Крыму!",
                metaDescription: "Авторские вины Крыма и эксклюзивные позиции алкогольных напитков премиум-класса со всего мира."
            });
        } else if (location.pathname === Routes.gastronomic_shop) {
            setMetaTags({
                title: "Гастрономическая лавка «Вино&Сыр»",
                metaTitle: "«Гастрономическая лавка&Сыр» премиальных продуктов в Крыму!",
                metaDescription: "У нас собраны самые изысканные деликатесы со всего мира: широкий ассортимент сыров, мясных изделий, морепродукты, высококачественная выпечка и много других разных вкусностей."
            });
        } else if (location.pathname === Routes.cafe) {
            setMetaTags({
                title: "BATYA29",
                metaTitle: "«BATYA29» – это нескучная еда на каждый день!",
                metaDescription: "Крышесносная пицца all day! Сытный завтрак до 14:00. Забронировать стол или заказать доставку в кафе «BATYA29»: +7 (978) 069-29-29"
            });
        } else if (location.pathname === Routes.merch) {
            setMetaTags({
                title: "Мерч от BATYA29",
                metaTitle: "Мерч от «BATYA29». Заказывай и переходи на Тёмину сторону!",
                metaDescription: "Не просто одежда, а всё, как для себя любимых: носки, рубашки, свитшоты, футболки, худи, чашки и шоперы. Все размеры в наличии!"
            });
        } else {
            setMetaTags({
                title: "Вино&Сыр&Batya29",
                metaTitle: "Гастрономический комплекс «Вино&Сыр» в Крыму!",
                metaDescription: "Ресторан. Винный магазин. Топовый эспрессо бар. Гастрономическая лавка. Индвидуальных подход и приятная атмосфера. Дизайнерский интерьер, не имеющий аналогов в Крыму."
            });
        }
    }, [location]);
    return metaTags;
}