import { FC, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import cn from 'classnames';

import playUrl from '../../../../assets/images/play.svg';
import pauseUrl from '../../../../assets/images/pause.svg';

import styles from './index.module.scss';
import { IGroupImage } from 'types/types';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import { useSelector } from 'react-redux';
import { dataSelector } from 'store/allDataSlice';
import { Pause, Play } from 'assets/icons';
import { IPosition } from 'pages/MainPage/types';

interface IntroBlockProps {
  data: IGroupImage;
}

const IntroBlock: FC<IntroBlockProps> = ({ data }) => {
  const [isPlay, setIsPlay] = useState<boolean>(false);
  const [isShowIcon, setIsShowIcon] = useState<boolean>(false);
  const apiData = useSelector(dataSelector);
  const [position, setPosition] = useState<IPosition>({
    left: 0,
    top: 0,
  });

  const handleMouseEnter = () => {
    setIsShowIcon(true);
  }

  const handleMouseMove = (e: any) => {
    if (window.innerWidth > 1279 && refVideo?.current) {
      const clientRect = refVideo?.current?.getBoundingClientRect();
      setPosition({
        left: e.clientX - clientRect.left,
        top: e.clientY - clientRect.top,
      });
    }
  };

  const handleMouseLeave = () => {
    setIsShowIcon(false);
  }

  const handleTglPlay = () => {
    setIsPlay(!isPlay);
  };

  //Intro
  const refIntro = useRef<HTMLDivElement | null>(null);
  const entryIntro = useIntersectionObserver(refIntro, {});
  const isVisibleIntro = !!entryIntro?.isIntersecting;

  //Video
  const refVideo = useRef<HTMLDivElement | null>(null);
  const entryVideo = useIntersectionObserver(refVideo, {});
  const isVisibleVideo = !!entryVideo?.isIntersecting;

  //Content
  const refContent = useRef<HTMLDivElement | null>(null);
  const entryContent = useIntersectionObserver(refContent, {});
  const isVisibleContent = !!entryContent?.isIntersecting;

  return (
    <div
      className={cn(styles.intro, { [styles.intro_active]: isVisibleIntro })}
      ref={refIntro}
    >

      <div
        className={cn(styles.intro__video, {
          [styles.intro__video_active]: isVisibleVideo,
        })}
        onClick={handleTglPlay}
        ref={refVideo}
        onMouseEnter={handleMouseEnter}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
      >
        <ReactPlayer
          url={apiData.data.config.video_file_merch}
          controls={false}
          loop={true}
          playsinline={true}
          width='100%'
          height='100%'
          stopOnUnmount={true}
          playing={isPlay}
        />

        <div
          className={cn(styles.intro__cursor, {
            [styles.intro__cursor_show]: isShowIcon
          })}
          style={{
            top: position.top,
            left: position.left
          }}
        >
          {
            !isPlay
              ? <Play />
              : <Pause />
          }
        </div>
      </div>
      <div
        className={cn(styles.intro__content, styles.content, {
          [styles.content_active]: isVisibleContent,
        })}
        ref={refContent}
      >
        <h3 className={styles.content__title}>{data.title}</h3>
        <p className={cn(styles.content__info, styles.content__info_main)}>
          {data.description}
        </p>
        <p className={cn(styles.content__info, styles.content__info_extra)}>
          {data.text_block1}
        </p>
      </div>
    </div>
  );
};

export { IntroBlock };
