import { Layout } from '../../components/Layout';
import { PageFooter } from '../../components/PageFooter';

import { PageContent } from '../../components/PageContent';
import { DescriptionBlock } from './sections/DescriptionBlock';
import { TitleBlock } from '../../components/PageBlocks/TitleBlock';
import { SliderBlock } from '../../components/PageBlocks/SliderBlock';
import { ProductsListBlock } from '../../components/PageBlocks/ProductsListBlock';
import { NextPageBlock } from '../../components/PageBlocks/NextPageBlock';

import styles from './index.module.scss';

import slideImg from '../../assets/images/gastronomic_shop/img_vine_cheese_slide.png';

import Routes from '../../constants/Routes';
import { useWindowWidth } from 'hooks/useWindowWidth';

import { RootState } from 'store/store';
import { useSelector } from 'react-redux';
import { filterProductsData } from 'utils/filterProducts';
import { useOffsetScroll } from 'hooks/useOffsetScroll';
import { useEffect, useState } from 'react';
import { useHeaderInvertContext } from 'context/headerInvert';

const pageData = {
	title: 'Гастрономическая лавка',
	footer: {
		info: 'У нас собраны самые изысканные деликатесы со всего мира.',
		link: ['Задать вопрос по лавке'],
		formTitle: 'Задать вопрос'
	},
	slider: [
		{ img: slideImg, alt: 'Картинка слайдера' },
		{ img: slideImg, alt: 'Картинка слайдера' },
		{ img: slideImg, alt: 'Картинка слайдера' },
		{ img: slideImg, alt: 'Картинка слайдера' },
		{ img: slideImg, alt: 'Картинка слайдера' },
		{ img: slideImg, alt: 'Картинка слайдера' },
		{ img: slideImg, alt: 'Картинка слайдера' },
		{ img: slideImg, alt: 'Картинка слайдера' },
		{ img: slideImg, alt: 'Картинка слайдера' }
	],
	description: {
		title: '«Гастрономическая лавка&Сыр» открылась в апреле 2018 г',
		info: 'У нас собраны самые изысканные деликатесы со всего мира.',
		extra: 'PS: хорошего сыра много не бывает!'
	},
	next: {
		name: 'Batya29',
		link: `${Routes.cafe}`
	}
};

const GastronomicShopPage = () => {
	const windowWidth = useWindowWidth();
	const [scrollElementNode, setScrollElementNode] =
		useState<HTMLDivElement | null>(null);
	const { setInvertProcentLogoRight } = useHeaderInvertContext();
	const lavka = useSelector((state: RootState) => state.data.data.lavka);

	const offset = useOffsetScroll(
		scrollElementNode,
		201,
		!!windowWidth && windowWidth > 1279
	);

	useEffect(() => {
		setInvertProcentLogoRight((100 / 201) * offset);
	}, [offset]); //eslint-disable-line

	useEffect(() => {
		return () => {
			setInvertProcentLogoRight(0);
		};
	}, []); //eslint-disable-line

	const descriptionData = {
		title: lavka.text_block1,
		info: lavka.text_block2,
		extra: lavka.text_block3
	};

	const productsData = {
		block: filterProductsData(lavka.prodcat, 'all')
	};

	return (
		<Layout className={styles.page}>
			<PageContent
				getElement={setScrollElementNode}
				className={styles.page__content}
			>
				<TitleBlock className={styles.page__title} type='h1'>
					{lavka.name}
				</TitleBlock>
				<SliderBlock
					className={styles.page__slider}
					data={lavka.images['group-slide']}
					convertColors
				/>
				<div className={styles.page__info}>{lavka.description}</div>
				<DescriptionBlock data={descriptionData} />
				<ProductsListBlock
					data={productsData}
					className={styles.page__cards}
					type='big'
					modalType='lavka'
				/>
				<NextPageBlock
					data={pageData.next}
					className={styles.page__next}
					type={'batya'}
					style={{
						transform: offset ? `translateX(${offset * -1}px)` : undefined
					}}
				/>
			</PageContent>
			{windowWidth && windowWidth > 1279 && (
				<PageFooter
					data={pageData.footer}
					className={styles.page__footer}
					formType={'red'}
					style={{
						transform: offset ? `translateX(${offset * -1}px)` : undefined
					}}
				/>
			)}
		</Layout>
	);
};

export { GastronomicShopPage };
