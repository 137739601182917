import useIntersectionObserver from 'hooks/useIntersectionObserver';
import { useRef } from 'react';
import styles from './index.module.scss';

import cn from 'classnames';

interface DescriptionBlockProps {
  data: {
    title: string;
    info: string;
    extra: string;
  };
}

const DescriptionBlock = ({ data }: DescriptionBlockProps) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(ref, {});
  const isVisible = !!entry?.isIntersecting;

  return (
    <>
      {data && (
        <div
          className={cn(styles.block, { [styles.block_active]: isVisible })}
          ref={ref}
        >
          <h3 className={styles.block__title}>{data?.title}</h3>
          <p className={styles.block__info}>{data?.info}</p>
          <p className={styles.block__extra}>{data?.extra}</p>
        </div>
      )}
    </>
  );
};

export { DescriptionBlock };
