import { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

import styles from './index.module.scss';
import {
	CloseSliderIcon,
	PlayIcon,
	VideoBtnBg,
	VideoBtnBgBatya
} from 'assets/icons';

import { IVideo } from './types';
import { IPosition } from 'pages/MainPage/types';
import ReactPlayer from 'react-player';
import Dot from '../Dot';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import IosModal from 'components/IosPopup';
import { useDetectOS } from 'hooks/useDetectOS';

const VideoBlock = ({ classname, url, title, batya, innerRef }: IVideo) => {
	const OS = useDetectOS();
	const notIphone = OS !== 'iOS';
	const [iosPopup, setIosPopup] = useState<boolean>(false);
	const data = useSelector((state: RootState) => state.data.data);

	const handle = useFullScreenHandle();
	const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
	const [isPlay, setIsPlay] = useState<boolean>(false);
	const [isMuted, setIsMuted] = useState<boolean>(true);
	const [playedSeconds, setPlayedSeconds] = useState<number>(0);
	const [loadedSeconds, setLoadedSeconds] = useState<number>(0);
	const [currentTime, setCurrentTime] = useState('00:00');
	const [isDotVisible, setIsDotVisible] = useState<boolean>(false);
	const [position, setPosition] = useState<IPosition>({
		left: 0,
		top: 0
	});

	const handleMouseMove = (e: any) => {
		setPosition({
			left: e.clientX,
			top: e.clientY
		});
	};

	const handleMouseLeave = () => {
		setIsDotVisible(false);
	};

	const handleMouseEnter = () => {
		setIsDotVisible(true);
	};

	useEffect(() => {
		if (notIphone) {
			isFullScreen ? handle.enter() : handle.exit();
		}
	}, [isFullScreen]);

	const handleProgress = (obj: any) => {
		setPlayedSeconds(obj.playedSeconds * 1000);
	};

	const handleChangeProgress = (e: any) => {
		!!innerRef?.current &&
			innerRef.current.seekTo(+e.target.value / 1000);
	};

	const videoReady = () => {
		if (loadedSeconds === 0) {
			// @ts-ignore
			setLoadedSeconds(innerRef.current.getDuration() * 1000);
		}
	};

	const handleReportChange = (state: boolean) => {
		setIsFullScreen(state);
		setIsPlay(state);
		setIsMuted(!state);
		// @ts-ignore
		!!innerRef?.current && innerRef.current.seekTo(0);
	};

	const tglIosPopup = () => {
		setIosPopup(!iosPopup);
		setIsPlay(!isPlay);
		setIsMuted(!isMuted);
		// @ts-ignore
		!!innerRef?.current && innerRef.current.seekTo(0);
	};

	const settings = {
		className: styles.video__player,
		width: '100%',
		height: '100%',
		progressInterval: 100,
		loop: true,
		controls: false,
		playsinline: true,
		stopOnUnmount: true,
		volume: 1,
		ref: innerRef
	};

	useEffect(() => {
		let minutes: number | string | boolean =
			!!innerRef?.current &&
			Math.floor(innerRef.current.getCurrentTime() / 60);
		let seconds: number | string | boolean =
			!!innerRef?.current &&
			Math.floor(innerRef.current.getCurrentTime() % 60);

		if (minutes < 10) {
			minutes = `0${minutes}`;
		}

		if (seconds < 10) {
			seconds = `0${seconds}`;
		}
		!!innerRef?.current && setCurrentTime(`${minutes}:${seconds}`);
	}, [playedSeconds]);

	return (
		<div
			className={cn(classname, styles.video, {
				[styles.video_batya]: batya
			})}
		>
			<div
				className={styles.video__bg}
				onClick={() => {
					if (notIphone) {
						setIsFullScreen(true)
					} else {
						tglIosPopup();
					}
				}}
			>
				{batya ? <VideoBtnBgBatya /> : <VideoBtnBg />}
			</div>

			<div className={styles.video__head}>
				<div className={styles.video__icon}>
					<PlayIcon batya={batya} />
				</div>
				<span className={styles.video__text}>
					{data.config.static_text.static_text_1}
				</span>
			</div>
			<h4 className={styles.video__title}>{title}</h4>

			{notIphone && (
				<FullScreen
					handle={handle}
					onChange={handleReportChange}
					className={cn(styles.video__fullscreen, {
						[styles.video__fullscreen_play]: !isPlay
					})}
				>
					{isFullScreen && (
						<>
							<div
								className={styles.video__area}
								onMouseEnter={handleMouseEnter}
								onMouseMove={handleMouseMove}
								onMouseLeave={handleMouseLeave}
								onClick={() => setIsPlay(!isPlay)}
							/>
							<Dot
								isDotVisible={isDotVisible}
								position={position}
								video
								isPlay={isPlay}
							/>
							<button
								className={styles.video__close}
								onClick={() => handle.exit()}
							>
								<CloseSliderIcon />
							</button>
							<div className={styles.video__controlls}>
								<input
									type='range'
									name='progress'
									id='progress'
									min='0'
									step='0.1'
									style={{
										backgroundSize:
											(playedSeconds * 100) / loadedSeconds + '% 100%'
									}}
									max={loadedSeconds}
									value={playedSeconds}
									onChange={handleChangeProgress}
									className={styles.video__progress}
								/>
							</div>

							<div className={styles.video__timeWrap}>
								<div
									className={styles.video__time}
									style={
										window.innerWidth > 743
											? {
												left: `calc(${(playedSeconds * 100) / loadedSeconds
													}%)`
											}
											: {
												left: `calc(${(playedSeconds * 100) / loadedSeconds
													}%)`
											}
									}
								>
									<span>{currentTime}</span>
								</div>
							</div>
							<LazyLoadComponent>
								<ReactPlayer
									onProgress={handleProgress}
									onReady={videoReady}
									url={url}
									playing={isPlay}
									muted={isMuted}
									{...settings}
								/>
							</LazyLoadComponent>
						</>
					)}
				</FullScreen>
			)}

			{!notIphone && (
				<IosModal visible={iosPopup}>
					<div
						className={styles.video__area}
						onMouseEnter={handleMouseEnter}
						onMouseMove={handleMouseMove}
						onMouseLeave={handleMouseLeave}
						onClick={() => setIsPlay(!isPlay)}
					/>

					<button
						className={styles.video__close}
						onClick={tglIosPopup}
					>
						<CloseSliderIcon />
					</button>

					<Dot
						isDotVisible={isDotVisible}
						position={position}
						video
						isPlay={isPlay}
					/>

					<div className={styles.video__controlls}>
						<input
							type='range'
							name='progress'
							id='progress'
							min='0'
							step='0.1'
							style={{
								backgroundSize: (playedSeconds * 100) / loadedSeconds + '% 100%'
							}}
							max={loadedSeconds}
							value={playedSeconds}
							onChange={handleChangeProgress}
							className={styles.video__progress}
						/>
					</div>

					<div className={styles.video__timeWrap}>
						<div
							className={styles.video__time}
							style={
								window.innerWidth > 743
									? {
										left: `calc(${(playedSeconds * 100) / loadedSeconds}%)`
									}
									: {
										left: `calc(${(playedSeconds * 100) / loadedSeconds}%)`
									}
							}
						>
							<span>{currentTime}</span>
						</div>
					</div>

					<ReactPlayer
						onProgress={handleProgress}
						onReady={videoReady}
						url={url}
						playing={isPlay}
						muted={isMuted}
						{...settings}
					/>
				</IosModal>
			)}
		</div>
	);
};

export default VideoBlock;
