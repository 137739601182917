import styles from './index.module.scss';
import cn from 'classnames';

import { Image } from '../../../../components/Image';
import { IGroupImage } from 'types/types';
import { useRef } from 'react';
import useIntersectionObserver from 'hooks/useIntersectionObserver';

interface DescriptionBlockProps {
  data: IGroupImage;
}

const DescriptionBlock = ({ data }: DescriptionBlockProps) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(ref, {});
  const isVisible = !!entry?.isIntersecting;
  
  return (
    <div 
    className={styles.block}
    ref={ref}
    >
      <Image
        src={data.original}
        alt={''}
        imageClass={styles.block__container_image}
        containerClass={cn(styles.block__container, {
          [styles.block__container_active]: isVisible
        })}
      />
      <div className={cn(styles.block__info, {
        [styles.block__info_active]: isVisible
      })}>{data.title}</div>
    </div>
  );
};

export { DescriptionBlock };
