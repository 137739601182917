import styles from './index.module.scss';
import cn from 'classnames';

import { useModal } from '../../hooks/useModal';

import { Modal } from '../Modal';

import { CSSTransition } from 'react-transition-group';
import { GastronomMask, VineMask } from 'assets/icons';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import { useRef } from 'react';
import { useWindowWidth } from 'hooks/useWindowWidth';

interface ProductCardProps {
  product: any;
  type?: 'big' | 'small';
  modalType?: 'lavka' | 'magaz';
  className?: string;
}

const ProductCard = ({
  product,
  type,
  modalType,
  className,
}: ProductCardProps) => {
  const { isShowing, toggle } = useModal();

  const ref = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(ref, {});
  const isVisible = !!entry?.isIntersecting;

  const windowWidth = useWindowWidth();
  return (
    <>
      <div
        className={cn(styles.card, className, {
          [styles.card_active]: isVisible,
        })}
        ref={ref}
        onClick={toggle}
      >
        <p className={styles.card__info}>{product?.title}</p>

        <div className={styles.card__img}>
          <img src={product?.preview?.original} alt={product?.preview?.title} />
        </div>
        <div className={styles.card__mask}>
          {modalType === 'lavka' && windowWidth && windowWidth > 1024
            ? <GastronomMask cnStroke={styles.card__stroke} />
            : <VineMask cnStroke={styles.card__stroke} />}

          {/* {type === 'big' && <GastronomMask cnStroke={styles.card__stroke} />} */}
          {/* {type === 'small' && <VineMask cnStroke={styles.card__stroke} />} */}
        </div>
      </div>
      <CSSTransition
        in={isShowing}
        timeout={window.innerWidth > 1279 ? 1300 : 1700}
        classNames={{
          enterActive: styles.modal_enter_active,
          enterDone: styles.modal_enter_done,
          exitActive: styles.modal_exit_active,
          exit: styles.modal_exit,
        }}
        mountOnEnter
        unmountOnExit
      >
        <Modal hide={toggle} data={product} modalType={modalType} />
      </CSSTransition>
    </>
  );
};

export { ProductCard };
