import { useEffect, useState } from "react";
import cn from "classnames";
import { CSSTransition } from "react-transition-group";
import { MorphReplace } from "react-svg-morph";

import styles from "./index.module.scss";

import { Menu } from "../Menu";

import {
  Burger,
  Close,
  CloseLine1,
  CloseLine2,
  Line,
  LogoBatya,
  LogoBatya29,
  LogoVine,
} from "assets/icons";
import { useLocation, useNavigate } from "react-router-dom";
import Routes from "constants/Routes";
import { useDispatch, useSelector } from "react-redux";
import {
  defaultIsMenu,
  defaultIsRouteFromMenu,
  setIsMenu,
  setRouteTo,
} from "store/mainSlice";
import { RootState } from "store/store";
import { useHeaderInvertContext } from "context/headerInvert";
import { ageSelector } from "store/ageSlice";

interface HeaderProps {
  type?: "light" | "dark";
  className?: string;
}

const Header = ({ type, className }: HeaderProps) => {
  const { isRouteFromMenu, routeTo, fullScreenSLider, isHoverSide } = useSelector(
    (state: RootState) => state.main
  );
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [isColorsLogo, setIsColorsLogo] = useState<string>("default");
  const { isAdult } = useSelector(ageSelector);

  const { invertProcentLogoRight } = useHeaderInvertContext();

  const colorsLogo = () => {
    if (
      location.pathname === Routes.home ||
      location.pathname === Routes.contacts ||
      (location.pathname === Routes.vine_shop && !isAdult)
    ) {
      setIsColorsLogo("default");
    } else if (location.pathname === Routes.career) {
      setIsColorsLogo("career");
    } else if (
      location.pathname === Routes.restaurant ||
      location.pathname === Routes.espresso_bar ||
      location.pathname === Routes.vine_shop ||
      location.pathname === Routes.gastronomic_shop
    ) {
      setIsColorsLogo("vine");
    } else {
      setIsColorsLogo("batya");
    }
  };

  useEffect(() => {
    colorsLogo();
    setIsMenuOpen(false);
  }, [location, isAdult]);

  useEffect(() => {
    isMenuOpen ? dispatch(setIsMenu()) : dispatch(defaultIsMenu());
  }, [isMenuOpen, dispatch]);

  const defaultTransitionOptions = {
    enterActive: styles.menu_enter_active,
    enterDone: styles.menu_enter_done,

    exitActive: isRouteFromMenu.vine
      ? styles.menu_exitVine_active
      : isRouteFromMenu.batya
        ? styles.menu_exitBatya_active
        : styles.menu_exit_active,

    exit: isRouteFromMenu.vine
      ? styles.menu_exitVine
      : isRouteFromMenu.batya
        ? styles.menu_exitBatya
        : styles.menu_exit,
  };

  const handleClickLogoVine = () => {
    location.pathname !== Routes.home
      ? dispatch(setRouteTo("vine"))
      : setIsMenuOpen(false)
  };
  const handleClickLogoBatya = () => {
    location.pathname !== Routes.cafe
      ? dispatch(setRouteTo("batya"))
      : setIsMenuOpen(false)
  };

  useEffect(() => {
    routeTo === "vine" && navigate(Routes.home);
    routeTo === "batya" && navigate(Routes.cafe);
  }, [routeTo, navigate]);

  return (
    <>
      <header
        className={cn(className, styles.header, {
          [styles.header_open]: isMenuOpen,
          [styles.header_batya]: location.pathname === Routes.home && fullScreenSLider === "batya",
          [styles.header_vine]: location.pathname === Routes.home && fullScreenSLider === "vine",
        })}
      >
        <div className={styles.header__wrapper}>
          <div
            className={cn(styles.header__logo, {
              [styles.header__logo_vine]:
                isColorsLogo === "vine" && !isMenuOpen,
              [styles.header__logo_batya]:
                isColorsLogo === "batya" && !isMenuOpen,
              [styles.header__logo_hide]: location.pathname === Routes.home && fullScreenSLider === "batya",
              [styles.header__logo_opacity]: isHoverSide === "batya"
                && location.pathname === Routes.home
                && !isMenuOpen
            })}
            onClick={handleClickLogoVine}
          >
            <LogoVine bg={styles.vineBg} text={styles.vineText} />
          </div>

          {/* бургер начало */}
          <div
            className={cn(styles.header__burger, styles.burger, {
              [styles.burger_open]: isMenuOpen,
              [styles.burger_vine]: isColorsLogo === "vine",
              [styles.burger_hide]: location.pathname === Routes.home && fullScreenSLider !== "",
            })}
            onClick={() => {
              setIsMenuOpen(!isMenuOpen);
            }}
          >
            <MorphReplace width={80} height={64} duration={400}>
              {!isMenuOpen ? <Burger key="open" /> : <Close key="close" />}
            </MorphReplace>

            <div
              className={cn(styles.burger__lineWrap, {
                [styles.burger__lineWrap_open]: !isMenuOpen,
              })}
            >
              <span className={cn(styles.burger__line, styles.burger__line_1)}>
                <Line />
              </span>
              <span className={cn(styles.burger__line, styles.burger__line_2)}>
                <Line />
              </span>
              <span className={cn(styles.burger__line, styles.burger__line_3)}>
                <Line />
              </span>
            </div>

            <div
              className={cn(styles.burger__closeLineWrap, {
                [styles.burger__closeLineWrap_open]: isMenuOpen,
              })}
            >
              <span
                className={cn(
                  styles.burger__closeLine,
                  styles.burger__closeLine_1
                )}
              >
                <CloseLine1 />
              </span>
              <span
                className={cn(
                  styles.burger__closeLine,
                  styles.burger__closeLine_2
                )}
              >
                <CloseLine2 />
              </span>
            </div>
          </div>
          {/* бургер конец */}

          <div className={styles.header__logoWrapper}>
            <div
              className={cn(styles.header__logo, styles.header__logoBatya, {
                [styles.header__logoBatya_inactive]:
                  isColorsLogo === "vine" && !isMenuOpen,
                [styles.header__logoBatya_hide]: fullScreenSLider === "vine",
                [styles.header__logoBatya_opacity]: isColorsLogo === "career",
                [styles.header__logo_opacity]: isHoverSide === "cafe"
                  && location.pathname === Routes.home
                  && !isMenuOpen
              })}
              onClick={handleClickLogoBatya}
            >
              {window.innerWidth > 743 && <LogoBatya />}
              <LogoBatya29 />
            </div>

            <div
              className={cn(
                styles.header__logo,
                styles.header__logoBatya,
                styles.header__logo_invert,
                {
                  [styles.header__logoBatya_inactive]:
                    isColorsLogo === "vine" && !isMenuOpen,
                  [styles.header__logoBatya_hide]: fullScreenSLider === "vine",
                }
              )}
              style={{
                clipPath: invertProcentLogoRight
                  ? `polygon(${100 - invertProcentLogoRight
                  }% 0, 100% 0, 100% 100%, ${100 - invertProcentLogoRight
                  }% 100%)`
                  : undefined,
              }}
              onClick={handleClickLogoBatya}
            >
              {window.innerWidth > 743 && <LogoBatya />}
              <LogoBatya29 />
            </div>
          </div>
        </div>

        <CSSTransition
          in={isMenuOpen}
          timeout={2000}
          classNames={defaultTransitionOptions}
          mountOnEnter
          unmountOnExit
          onExited={() => dispatch(defaultIsRouteFromMenu())}
        >
          <Menu />
        </CSSTransition>
      </header>
    </>
  );
};

export { Header };
