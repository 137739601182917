import { ReactNode, useState } from "react";
import { HeaderInvertContext } from "./headerInvert";

interface IContextProvider {
  children: ReactNode;
}

export const ContextProvider = ({ children }: IContextProvider) => {
  const [invertProcentLogoRight, setInvertProcentLogoRight] =
    useState<number>(0);

  return (
    <HeaderInvertContext.Provider
      value={{ invertProcentLogoRight, setInvertProcentLogoRight }}
    >
      {children}
    </HeaderInvertContext.Provider>
  );
};
