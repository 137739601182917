import { YMaps, Map, Placemark } from 'react-yandex-maps';
import cn from 'classnames';
import { Button } from 'components/UI/Button';

import styles from './index.module.scss';
import './index.scss';
import { useModal } from 'hooks/useModal';
import { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { FormModal } from 'layouts/FormModal';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';

interface MapProps {
	data: {
		default: {
			coord: number[];
			zoom: number;
		};
		marks: {
			coord: number[];
			pin: string;
		}[];
	};
	className?: string;
}

const MapBlock = ({ className, data }: MapProps) => {
	const mainData = useSelector((state: RootState) => state.data.data);

	const { isShowing, toggle } = useModal();
	const [formName, setFormName] = useState<string>('');

	const handleClick = () => {
		setFormName('notify');
		toggle();
	};

	return (
		<>
			<div className={cn(styles.map, className)}>
				<div className={cn(styles.map__content, styles.mapBlock)}>
					<div
						className={cn(styles.mapBlock__side, styles.mapBlock__side_left)}
					>
						<ul className={styles.mapBlock__list}>
							{!!mainData.social.magaz.phone && (
								<li className={styles.mapBlock__item}>
									<h6 className={styles.mapBlock__subtitle}>
										Винный магазин <span>&</span> Гастрономическая лавка
									</h6>
									<a
										href={`tel:+${mainData.social.magaz.phone}`}
										className={styles.mapBlock__link}
									>
										<span>+{mainData.social.magaz.phone}</span>
									</a>
								</li>
							)}
							{!!mainData.social.rest.phone && (
								<li className={styles.mapBlock__item}>
									<h6 className={styles.mapBlock__subtitle}>ресторан</h6>
									<a
										href={`tel:+${mainData.social.rest.phone}`}
										className={styles.mapBlock__link}
									>
										<span>+{mainData.social.rest.phone}</span>
									</a>
								</li>
							)}
						</ul>
					</div>
					<div
						className={cn(styles.mapBlock__side, styles.mapBlock__side_right)}
					>
						<ul className={styles.mapBlock__list}>
							{!!mainData.social.b29.phone && (
								<li className={styles.mapBlock__item}>
									<h6 className={styles.mapBlock__subtitle}>кафе</h6>
									<a
										href={`tel:+${mainData.social.b29.phone}`}
										className={styles.mapBlock__link}
									>
										<span>+{mainData.social.b29.phone}</span>
									</a>
								</li>
							)}
						</ul>
					</div>

					<div className={cn(styles.mapBlock__footer, styles.footer)}>
						<div
							className={cn(styles.footer__block, styles.footer__block_left)}
						>
							<ul className={styles.footer__list}>
								{!!mainData.social.magaz.instagram && (
									<li className={styles.footer__item}>
										<a
											href={mainData.social.magaz.instagram}
											className={styles.footer__link}
											target={'_blank'}
										>
											instagram
										</a>
									</li>
								)}
								{!!mainData.social.magaz.telegram && (
									<li className={styles.footer__item}>
										<a
											href={mainData.social.magaz.telegram}
											className={styles.footer__link}
											target={'_blank'}
										>
											telegram
										</a>
									</li>
								)}
								{!!mainData.social.magaz.vkontakte && (
									<li className={styles.footer__item}>
										<a
											href={mainData.social.magaz.vkontakte}
											className={styles.footer__link}
											target={'_blank'}
										>
											vk
										</a>
									</li>
								)}
							</ul>
						</div>
						<div
							className={cn(styles.footer__block, styles.footer__block_middle)}
						>
							<ul className={styles.footer__list}>
								{!!mainData.social.email && (
									<li className={styles.footer__item}>
										<a
											href={`mailto:${mainData.social.email}`}
											className={styles.footer__link}
										>
											{mainData.social.email}
										</a>
									</li>
								)}
							</ul>
						</div>
						<div
							className={cn(styles.footer__block, styles.footer__block_right)}
						>
							<ul className={styles.footer__list}>
								{!!mainData.social.b29.vkontakte && (
									<li className={styles.footer__item}>
										<a
											href={mainData.social.b29.vkontakte}
											className={styles.footer__link}
											target={'_blank'}
										>
											vk
										</a>
									</li>
								)}
								{!!mainData.social.b29.telegram && (
									<li className={styles.footer__item}>
										<a
											href={mainData.social.b29.telegram}
											className={styles.footer__link}
											target={'_blank'}
										>
											telegram
										</a>
									</li>
								)}
								{!!mainData.social.b29.instagram && (
									<li className={styles.footer__item}>
										<a
											href={mainData.social.b29.instagram}
											className={styles.footer__link}
											target={'_blank'}
										>
											instagram
										</a>
									</li>
								)}
							</ul>
						</div>
					</div>
				</div>
				<YMaps>
					<Map
						defaultState={{
							center: data.default.coord,
							zoom: data.default.zoom,
							controls: []
						}}
						options={{
							suppressMapOpenBlock: true
						}}
						className={styles.map__inner}
					>
						{data.marks.map((n, i) => {
							return (
								<Placemark
									key={i}
									geometry={n.coord}
									options={{
										iconLayout: 'default#image',
										iconImageHref: n.pin,
										iconImageSize:
											window.innerWidth > 743 ? [60, 60] : [30, 30],
										iconImageOffset: [-3, -42]
									}}
								/>
							);
						})}
					</Map>
					<div className={styles.mapContent__address}>
						<p>Адрес: кипарисное, улица алуштинская, 11б</p>
					</div>

					<Button onClick={handleClick} className={styles.mapContent__btn}>
						<span>форма обратной связи</span>
					</Button>

					<div
						className={cn(
							styles.mapContent__address,
							styles.mapContent__address_right
						)}
					>
						<p>Адрес: кипарисное, улица алуштинская, 14в</p>
					</div>

					<a
						href='https://yandex.ru/maps/-/CCUnj0sadA'
						target='_blank'
						className={styles.mapContent__link}
					>
						<span>Построить маршрут: Вино&Сыp</span>
					</a>
					<a
						href='https://yandex.ru/maps/-/CCUn4Qr~2D'
						target='_blank'
						className={cn(
							styles.mapContent__link,
							styles.mapContent__link_right
						)}
					>
						<span>построить маршрут: кафе&nbsp;29</span>
					</a>
				</YMaps>
			</div>
			<CSSTransition
				in={isShowing}
				timeout={1000}
				classNames={{
					enterActive: styles.modal_enter_active,
					enterDone: styles.modal_enter_done,
					exitActive: styles.modal_exit_active,
					exit: styles.modal_exit
				}}
				mountOnEnter
				unmountOnExit
			>
				<FormModal
					hide={toggle}
					formType={'cold'}
					formName={formName}
					formTitle={'Задать вопрос'}
				/>
			</CSSTransition>
		</>
	);
};

export { MapBlock };
