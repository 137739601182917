import styles from './index.module.scss';

import cn from 'classnames';

import { Image } from '../../../../components/Image';
import { TitleBlock } from '../../../../components/PageBlocks/TitleBlock';
import { useWindowWidth } from 'hooks/useWindowWidth';

import { CSSTransition } from 'react-transition-group';
import { useModal } from 'hooks/useModal';
import { FormModal } from 'layouts/FormModal';
import { useRef } from 'react';
import useIntersectionObserver from 'hooks/useIntersectionObserver';

interface AboutBlockProps {
  data: {
    title: string;
    main_info: string;
    img: {
      href: string;
      alt: string;
    }[];
    info: string;
  };
}

const AboutBlock = ({ data }: AboutBlockProps) => {
  const windowWidth = useWindowWidth();
  //Info 1//
  const refInfo1 = useRef<HTMLDivElement | null>(null);
  const entryInfo1 = useIntersectionObserver(refInfo1, {});
  const isVisibleInfo1 = !!entryInfo1?.isIntersecting;

  //Image 1//
  const refImage1 = useRef<HTMLDivElement | null>(null);
  const entryImage1 = useIntersectionObserver(refImage1, {});
  const isVisibleImage1 = !!entryImage1?.isIntersecting;

  //Info 2//
  const refInfo2 = useRef<HTMLDivElement | null>(null);
  const entryInfo2 = useIntersectionObserver(refInfo2, {});
  const isVisibleInfo2 = !!entryInfo2?.isIntersecting;

  //Image 2//
  const refImage2 = useRef<HTMLDivElement | null>(null);
  const entryImage2 = useIntersectionObserver(refImage2, {});
  const isVisibleImage2 = !!entryImage2?.isIntersecting;

  //Block//
  const refBlock = useRef<HTMLDivElement | null>(null);
  const entryBlock = useIntersectionObserver(refBlock, {});
  const isVisibleBlock = !!entryBlock?.isIntersecting;

  const { isShowing, toggle } = useModal();
  return (
    <>
      <div className={styles.about}>
        <TitleBlock className={styles.about__title}>{data.title}</TitleBlock>
        <div
          className={cn(styles.about__block, styles.block, {
            [styles.about__block_active]: isVisibleBlock,
          })}
          ref={refBlock}
        >
          <div className={cn(styles.block__section, styles.section)}>
            <p
              className={cn(styles.section__info, {
                [styles.section__info_active]: isVisibleInfo1,
              })}
              ref={refInfo1}
            >
              {data.main_info}
            </p>
            <Image
              src={data.img[0].href}
              alt={data.img[0].alt}
              imageClass={styles.section__container_image}
              containerClass={cn(styles.section__container, {
                [styles.section__container_active]: isVisibleImage1,
              })}
              ref={refImage1}
            />
          </div>
          <div className={cn(styles.block__section, styles.section)}>
            <p
              className={cn(styles.section__info, {
                [styles.section__info_active]: isVisibleInfo2,
              })}
              ref={refInfo2}
            >
              {data.info}
            </p>
            <Image
              src={data.img[1].href}
              alt={data.img[1].alt}
              imageClass={styles.section__container_image}
              containerClass={cn(styles.section__container, {
                [styles.section__container_active]: isVisibleImage2,
              })}
              ref={refImage2}
            />
          </div>
        </div>
        {windowWidth && windowWidth <= 1279 && (
          <h5 className={styles.link} onClick={toggle}>
            Забронировать столик
          </h5>
        )}
      </div>
      <CSSTransition
        in={isShowing}
        timeout={1000}
        classNames={{
          enterActive: styles.modal_enter_active,
          enterDone: styles.modal_enter_done,
          exitActive: styles.modal_exit_active,
          exit: styles.modal_exit,
        }}
        mountOnEnter
        unmountOnExit
      >
        <FormModal hide={toggle} formType={'red'} formName={'booking'} />
      </CSSTransition>
    </>
  );
};

export { AboutBlock };
