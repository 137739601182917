import styles from './index.module.scss';
import { IGroupImage } from 'types/types';
import AdvBlock from './components/AdvBlock';

interface AdvertisingBlockProps {
  data: IGroupImage[];
}

const AdvertisingBlock = ({ data }: AdvertisingBlockProps) => {
  
  const textTransform = (value: string) => {
    const splitted: any = value.split('«');
    if (splitted % 2 !== 0 && value.includes('«') && value.includes('»')) {
      return value.replace(/«/g, '<span>«').replace(/»/g, '»</span>');
    }
    return value;
  };

  return (
    <div className={styles.advertising}>
      {!!data.length && data.map((adv: IGroupImage) => (
        <AdvBlock 
          key={adv.id}
          classname={styles.advertising__content}
          text={textTransform(adv.title)}
          src={adv.original}
        />
      ))}
    </div>
  );
};

export { AdvertisingBlock };
