import styles from './index.module.scss';

import cn from 'classnames';

import { useModal } from 'hooks/useModal';

import { CSSTransition } from 'react-transition-group';

import { FormModal } from 'layouts/FormModal';
import { useRef, useState } from 'react';
import useIntersectionObserver from 'hooks/useIntersectionObserver';

interface IJobCard {
	job: any;
	setCurrentJob: React.Dispatch<React.SetStateAction<any>>;
}

const JobCard = ({ job, setCurrentJob }: IJobCard) => {
	const ref = useRef<HTMLDivElement | null>(null);
	const entry = useIntersectionObserver(ref, { threshold: 0.4 });
	const isVisible = !!entry?.isIntersecting;
	return (
		<div
			className={cn(styles.jobs__content, styles.content, {
				[styles.jobs__content_active]: isVisible
			})}
			onClick={() => setCurrentJob(job.title)}
			ref={ref}
		>
			<h4 className={styles.content__title}>{job.title}</h4>
			<p className={styles.content__info}>{job.description}</p>
		</div>
	);
};

const JobsBlock = ({ data }: any) => {
	const { isShowing, toggle } = useModal();
	const [currentJob, setCurrentJob] = useState<string>('');

	return (
		<>
			{!!data && data.length >= 1 && (
				<div className={styles.jobs} onClick={toggle}>
					{data.map((job: any, index: number) => (
						<JobCard job={job} key={index} setCurrentJob={setCurrentJob} />
					))}
				</div>
			)}
			{!!data && data.length === 0 && (
				<p className={styles.content__empty}>
					В данный момент нет опубликованных вакансий
				</p>
			)}
			<CSSTransition
				in={isShowing}
				timeout={1000}
				classNames={{
					enterActive: styles.modal_enter_active,
					enterDone: styles.modal_enter_done,
					exitActive: styles.modal_exit_active,
					exit: styles.modal_exit
				}}
				mountOnEnter
				unmountOnExit
			>
				<FormModal
					hide={toggle}
					formType={'cold'}
					formName={'job'}
					formTitle={currentJob}
				/>
			</CSSTransition>
		</>
	);
};

export { JobsBlock };
