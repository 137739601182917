import styles from "./index.module.scss";

import { Link } from "react-router-dom";
import { TitleBlock } from "../TitleBlock";
import { Image } from "../../Image";
import batya from "../../../assets/images/next/batya_next.svg";
import batya_mob from "../../../assets/images/next/batya_next_mob.svg";
import vine_cheese from "../../../assets/images/next/vine-cheese_next.svg";
import vine_cheese_mob from "../../../assets/images/next/vine-cheese_next_mob.svg";

import cn from "classnames";
import { useDispatch } from "react-redux";
import { CSSProperties, useEffect } from "react";
import { setScrollTo } from "store/mainSlice";

interface AdvertisingBlockProps {
  data: {
    name: string;
    link: string;
  };
  className?: string;
  style?: CSSProperties;
  type?: "batya" | "vine-cheese";
}

const NextPageBlock = ({
  data,
  className,
  type,
  style,
}: AdvertisingBlockProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setScrollTo(data.link));
  }, []);

  return (
    <Link to={data.link} className={cn(styles.next, className)} style={style}>
      <p className={styles.next__link}>Далее</p>
      {!type && (
        <TitleBlock className={styles.next__title} type="h4">
          {data.name}
        </TitleBlock>
      )}
      {type === "batya" && (
        <Image
          src={window.innerWidth > 1279 ? batya : batya_mob}
          alt={"batya"}
          imageClass={styles.next__container_image}
          containerClass={styles.next__container}
        />
      )}
      {type === "vine-cheese" && (
        <Image
          src={
            window.innerWidth > 1279 ? vine_cheese : vine_cheese_mob
          }
          alt={"vine_cheese"}
          imageClass={styles.next__container_image}
          containerClass={styles.next__container}
        />
      )}
    </Link>
  );
};

export { NextPageBlock };
