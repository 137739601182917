export const storageInteraction = <T>(
    key: string,
    initialValue?: T
  ): T | null => {
    const storange = localStorage.getItem(key);
  
    if (typeof initialValue === "undefined") {
      return storange && JSON.parse(storange);
    }
  
    if (initialValue === null) {
      localStorage.removeItem(key);
      return null;
    }
  
    localStorage.setItem(key, JSON.stringify(initialValue));
    return initialValue;
};